import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent, eventOn } from './helpers/global';
import Storage from './helpers/Storage';
import appConfig from './config/';
import NotificationsController from './helpers/NotificationsController';

import LoginView from './components/LoginView';
import HeaderView from './components/HeaderView';
import LoadIndicator from './components/LoadIndicator';
import SelectOptionPopup from './components/common/SelectOptionPopup';
import ConfirmationPopup from './components/common/ConfirmationPopup';
import ContentPopup from './components/common/ContentPopup';
import FramePopup from './components/common/FramePopup';
import NotificationPopup from './components/common/NotificationPopup';
import Snackbar from './components/common/Snackbar';

import ObjectListView from './components/ObjectListView';
import ObjectEditView from './components/ObjectEditView';
import DashboardView from './components/DashboardView';

import SettingsView from './components/SettingsView';
import NotificationsView from './components/NotificationsView';

// Market Map views
import MarketMapCategoryListView from './components/market_map/MarketMapCategoryListView';
import MarketMapCategoryFormView from './components/market_map/MarketMapCategoryFormView';
import MarketMapCompanyBulkUploadView from './components/market_map/MarketMapCompanyBulkUploadView';
import MarketMapCompanyListView from './components/market_map/MarketMapCompanyListView';
import MarketMapCompanyRequestList from './components/market_map/MarketMapCompanyRequestList';
import MarketMapCompanyFormView from './components/market_map/MarketMapCompanyFormView';
import MarketMapInformationListView from './components/market_map/MarketMapInformationListView';
import MarketMapInformationFormView from './components/market_map/MarketMapInformationFormView';
import MarketMapListView from './components/market_map/MarketMapListView';
import MarketMapFormView from './components/market_map/MarketMapFormView';
import MarketMapRequestList from './components/market_map/MarketMapRequestList';

const filterStandAlone = (key) => !appConfig[key].config.standalone;
const adminListRoutes = Object.keys(appConfig)
  .filter(filterStandAlone)
  .map((key) => `/${key}`);
const adminEditRoutes = Object.keys(appConfig)
  .filter(filterStandAlone)
  .map((key) => `/${key}/:id`);

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // if (!rest.user) return <Redirect to="/login" />;
      const key = props.location.pathname.substr(1).split('/')[0];
      if (!appConfig[key] && ['notifications'].indexOf(key) < 0)
        return <Redirect to="/dashboard" />;
      if (appConfig[key] && appConfig[key].config.adminOnly && !rest.user.admin)
        return <Redirect to="/dashboard" />;
      return <Component {...props} configKey={key} />;
    }}
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !rest.user ? <Component {...props} /> : <Redirect to="/dashboard" />
    }
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
    };
    this.loadCount = 0;

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
    eventOn('unauthorized', this.onUnauthorized);
    eventOn('forbidden', this.onForbidden);

    eventOn(
      'resize',
      (e) => {
        Storage.setSetup('is_mobile', window.innerWidth <= 768);
      },
      window
    );
    Storage.setSetup('is_mobile', window.innerWidth <= 768);
  }

  componentDidUpdate = (prevProps, prevState) => {};

  addLoad = () => {
    this.loadCount++;
    if (this.state.showLoadIndicator !== this.loadCount > 0) {
      this.setState({ showLoadIndicator: this.loadCount > 0 });
    }
  };

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== this.loadCount > 0) {
      this.setState({ showLoadIndicator: this.loadCount > 0 });
    }
  };

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('thesdg-admin:user_attributes');
  };

  onUnauthorized = () => {
    triggerEvent('showSnackbar', [
      {
        text: 'Your access token has been expired. Please re-login.',
        type: 'error',
      },
    ]);
    this.onLogout(true);
  };

  onForbidden = () => {
    triggerEvent('showSnackbar', [
      { text: 'You must be an admin to access this page.', type: 'error' },
    ]);
    this.props.history.goBack();
  };

  componentDidMount = () => {
    if (this.props.user) {
      sendRequest({
        method: 'me/',
        type: 'GET',
        success: (data) => {
          Storage.setData('user', data);
        },
        error: (data) => {},
      });
    }
  };

  render = () => {
    const user = this.props.user;
    const hideUI = false;
    return (
      <div>
        {hideUI ? null : <HeaderView onLogout={this.onLogout} />}
        <div
          className={classnames({
            adminContent: true,
            customScrollBar: true,
            showUI: !hideUI,
          })}
        >
          <Switch>
            <AdminRoute
              user={user}
              path="/dashboard"
              component={DashboardView}
            />
            <AdminRoute
              user={user}
              path="/notifications"
              component={NotificationsView}
            />
            <AdminRoute user={user} path="/settings" component={SettingsView} />
            <AdminRoute
              user={user}
              path="/market-maps/create"
              component={MarketMapFormView}
            />
            <AdminRoute
              user={user}
              path="/market-maps/:id"
              component={MarketMapFormView}
            />
            <AdminRoute
              user={user}
              path="/market-maps"
              component={MarketMapListView}
            />
            <AdminRoute
              user={user}
              path="/market-map-categories/create"
              component={MarketMapCategoryFormView}
            />
            <AdminRoute
              user={user}
              path="/market-map-categories/:id"
              component={MarketMapCategoryFormView}
            />
            <AdminRoute
              user={user}
              path="/market-map-categories"
              component={MarketMapCategoryListView}
            />
            <AdminRoute
              user={user}
              path="/market-map-companies/bulk-upload"
              component={MarketMapCompanyBulkUploadView}
            />
            <AdminRoute
              user={user}
              path="/market-map-companies/create"
              component={MarketMapCompanyFormView}
            />
            <AdminRoute
              user={user}
              path="/market-map-companies/:id"
              component={MarketMapCompanyFormView}
            />
            <AdminRoute
              user={user}
              path="/market-map-companies"
              component={MarketMapCompanyListView}
            />
            <AdminRoute
              user={user}
              path="/market-map-informations/create"
              component={MarketMapInformationFormView}
            />
            <AdminRoute
              user={user}
              path="/market-map-informations/:id"
              component={MarketMapInformationFormView}
            />
            <AdminRoute
              user={user}
              path="/market-map-informations"
              component={MarketMapInformationListView}
            />
            <AdminRoute
              user={user}
              path="/market-map-requests"
              component={MarketMapRequestList}
            />
            <AdminRoute
              user={user}
              path="/market-map-company-requests"
              component={MarketMapCompanyRequestList}
            />
            <AdminRoute
              user={user}
              path={adminEditRoutes}
              component={ObjectEditView}
            />
            <AdminRoute
              user={user}
              path={adminListRoutes}
              component={ObjectListView}
            />
            <LoginRoute user={user} path="/" component={LoginView} />
          </Switch>
        </div>

        <LoadIndicator show={this.state.showLoadIndicator} />
        <NotificationsController />
        <ConfirmationPopup global />
        <SelectOptionPopup global />
        <ContentPopup global />
        <FramePopup global />
        <NotificationPopup />
        <Snackbar />
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(App));
