import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import DateInput from './input/DateInput.js';
import TextInput from './input/TextInput.js';

import { sendRequest, triggerEvent } from '../helpers/global.js';

import '../sass/components/ProposalView.scss';

export default class ProposalView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expiredTime: props.object.expired_at_timestamp,
      suggestedPrice: props.object.suggested_price_in_cents / 100,
      sharesNumber: props.object.number_of_shares,
    };
  }

  handleDateChange = (key, e) => {
    this.setState({expiredTime: e});
  }

  handleSuggestedPrice = (key, e) => {
    this.setState({suggestedPrice: e});
  }

  handleSharesNumber = (key, e) => {
    this.setState({sharesNumber: e});
  }

  saveChanges = (key, value) => {
    sendRequest({
      method: `proposals/${this.props.object.id}`,
      type: "PUT",
      data: {
        [key]: value
      },
      success: (data) => {
        this.props.onChange(key, value);
        triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  applyAction = (action) => {
    sendRequest({
      type: 'POST',
      method: `proposals/${this.props.object.id}/apply_action`,
      data: {
        action: action
      },
      success: (data) => {
        this.props.onChange('status', data.status);
        this.setState({expiredTime: data.expired_at_timestamp});
        triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
      },
      error: (data) => {
        triggerEvent('showSnackbar', [{text: data.error, type: 'error'}]);
      }
    });
  }

  addTagForm = (name) => {
    return (
      <div
        className='availableTag'
        onClick={() => this.saveChanges(name, true)}
      >
        <i className='material-icons addTag'>add</i>
        <div className='tagTitle'>tag as {name}</div>
      </div>
    )
  }

  removeTagForm = (name) => {
    return (
      <div>
        <div className='tagged'>{name}
          <i
            className='material-icons untag'
            onClick={() => this.saveChanges(name, false)}
          >
            clear
          </i>
        </div>
      </div>
    )
  }

  renderActions = (status) => {
    switch(status) {
      case 'pending':
        return (
          <div className='actionsContainer'>
            <button
              className='proposalApprove'
              onClick={() => this.applyAction('approve')}
            >
              Approve
            </button>
            <div className='or'></div>
            <button
              className='proposalReject'
              onClick={() => this.saveChanges('status', 'rejected')}
            >
              Reject
            </button>
            <div className='or'></div>
            <Link
              className='proposalEdit'
              to={`/proposals/${this.props.object.id}`}
            >
              Edit
            </Link>
          </div>
        );
      case 'live':
      case 'expired':
        return (
          <div className='actionsContainer'>

          {this.props.object.raised_amount >= this.props.object.target_amount ? <>
            <button
              className='proposalComplete'
              onClick={() => this.saveChanges('status', 'complete')}
            >
              Complete
            </button>
            </>:
            <>
            <button
              className='proposalFail'
              onClick={() => this.saveChanges('status', 'failed')}
            >
              Fail
            </button>
            </>
          }

            <div className='or'></div>
            <button
              className='proposalClose'
              onClick={() => this.saveChanges('status', 'closed')}
            >
              Close
            </button>
            <div className='or'></div>
            <button
              className='proposalExtend'
              onClick={() => this.applyAction('extend')}
            >
              Extend
            </button>
            <div className='or'></div>
            <Link
              className='proposalEdit'
              to={`/proposals/${this.props.object.id}`}
            >
              Edit
            </Link>
          </div>

        );
      case 'failed':
        return (
          <div className='actionsContainer'>
            <button
              className='button proposalClose'
              onClick={() => this.saveChanges('status', 'closed')}
              style={{'borderRadius': '3px'}}
            >
              Close
            </button>
          </div>

        );
      case 'complete':
        return (
          <div className='actionsContainer'>
            <button
              className='proposalReopen'
              onClick={() => this.applyAction('reopen')}
            >
              Reopen
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  renderProposal = () => {
    const object = this.props.object;

    return (
      <div className='proposalView'>
        <Link
          className='title'
          to={`/proposals/${object.id}`}
        >
          {object.title}
        </Link>

        <span className={`status ${object.status}`}>
          {object.status}
        </span>

        <div className='expiredDate'>
          <DateInput
            properties={{simplified: false}}
            object={this.state.expiredTime}
            objectKey='expired_at_timestamp'
            onChange={this.handleDateChange}
          />
        </div>

        <button
          className='updateDate'
          onClick={() => this.saveChanges('expired_at', moment.unix(this.state.expiredTime))}
        >
          Update
        </button>

        <div className='proposalContainer'>
          {object.financing_option === 'fundedx' ?
            <>
              <div className='suggestedPrice'>
                Share Suggested Price $:
              </div>
              <TextInput
                properties={{type: 'text'}}
                object={this.state.suggestedPrice}
                onChange={this.handleSuggestedPrice}
              />
              <button
                className='updateRaisedAmount'
                onClick={() => this.saveChanges('suggested_price_in_cents', this.state.suggestedPrice * 100)}
              >
                Update
              </button>
            </>
          : null}
        </div>

        <div className='createdDate'>
          <span>Created: {moment.unix(object.created_at_timestamp).format('DD.MM.YYYY')}</span>
        </div>

        {this.renderActions(object.status)}

        {object.funded ? this.removeTagForm('funded') : this.addTagForm('funded')}

        {object.listco_bond ?
          this.removeTagForm('listco_bond') : this.addTagForm('listco_bond')
        }

         {object.financing_option === 'equity' ?
            object.status === 'pending' ?
             <div className='suggested'>
               <div className='suggestedPrice suggestedItem'>
                 Number of Shares:
               </div>
               <TextInput
                 className='suggestedItem'
                 properties={{type: 'text'}}
                 object={this.state.sharesNumber}
                 onChange={this.handleSharesNumber}
               />
             </div> :
             <div className='shares'>
               <b className='sharesNumber'>Number of Shares:</b>
               {this.state.sharesNumber}
             </div>
         : null}
      </div>
    )
  }

  render = () => {
    return this.renderProposal();
  }
}
