import React from 'react';
import { connect } from 'react-redux';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import DocumentsViewItem from '../DocumentsViewItem.js';

import '../../sass/components/input/DocumentsList.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class DocumentsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.input = null;
  }

  componentDidMount = () => {
  }

  updateData = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  uploadFiles = (files) => {
    let formData = new FormData();
    if (this.props.properties.documentType) {
      formData.append('document_type', this.props.properties.documentType);
    }
    files.forEach((file, i) => {
      formData.append(`files[${i}]`, file);
    });
    sendRequest({
      method: `proposals/${this.props.parentObject.id}/documents/`,
      type: 'POST',
      formData,
      success: (data) => {
        this.updateData([
          ...this.props.object,
          ...data,
        ])
      },
      error: (data) => {
        if (data.errors) {
          triggerEvent('showSnackbar', [{text: data.errors.file, type: 'error'}]);
        } else {
          triggerEvent('showSnackbar', [{text: data.error, type: 'error'}]);
        }
      }
    });
  }

  downloadAll = () => {
    const ids = (this.props.object || []).map(i => i.id);
    sendRequest({
      method: 'documents/download',
      type: 'GET',
      data: {
        ids,
      },
      success: (data) => {
        const link = document.createElement('a');
        link.href = data.url;
        link.download = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error: (data) => {
      }
    });
  }

  renderAddButton = () => {
    return (
      <>
        <button
          className='addButton outline'
          onClick={() => this.input.click()}
        >
          Upload New
        </button>
        <input
          type='file'
          multiple
          ref={input => this.input = input}
          onChange={e => this.uploadFiles([...e.target.files])}
        />
      </>
    )
  }

  renderDocument = (doc) => {
    return (
      <DocumentsViewItem
        key={doc.id}
        embedded
        types={this.state.types || []}
        object={doc}
        method={`proposals/${this.props.parentObject.id}/documents/`}
        title='Document'
        onChange={(updated) => {
          this.updateData(
            this.props.object.map(i => i.id === doc.id ? updated : i)
          )
        }}
        onDelete={() => {
          this.updateData(
            this.props.object.filter(i => i.id !== doc.id)
          )
        }}
      />
    )
  }

  renderDownloadAll = () => {
    return (
      <button
        className='uploadLink outline'
        onClick={this.downloadAll}
      >
        Download all
      </button>
    )
  }

  render = () => {
    const documents = (this.props.object || []);

    return (

      <div className='documentsList'>
        {documents.length
          ? <div className='listContainer'>
              {this.props.isMobile
                ? documents.map(this.renderDocument)
                : <table>
                  <tbody>
                    {documents.map(this.renderDocument)}
                  </tbody>
                </table>
              }
            </div>
          : <div className='listPlaceholder'>No documents uploaded</div>
        }

        <div className='documents'>

          {this.props.properties.uploadLink ?
            <div className='uploadLinks left-btn'>
              {documents.length ? this.renderDownloadAll() : null}
            </div>
          : null}

          <div className='right-btn'>
            <button
              className='addButton outline'
              onClick={() => this.input.click()}
            >
              Upload New
            </button>
            <input
              type='file'
              multiple
              ref={input => this.input = input}
              onChange={e => this.uploadFiles([...e.target.files])}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(DocumentsList);
