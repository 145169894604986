import React from 'react';

import TextInput from './TextInput.js';
import ChecklistInput from './ChecklistInput.js';

import '../../sass/components/input/ChecklistsGroupInput.scss';

export default class ChecklistsGroupInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  updateItem = (itemId, key, value) => {
    const object = this.props.object.map(i =>
      i.id === itemId ? { ...i, [key]: value } : i
    );
    this.handleChange(object);
  }

  addItem = () => {
    const object = [...this.props.object, {
      id: `$${Date.now()}`,
      name: '',
      items: [],
    }];
    this.handleChange(object);
  }

  deleteItem = (itemId) => {
    const object = this.props.object.filter(i =>
      i.id !== itemId
    );
    this.handleChange(object);
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='checklistsGroupInput'>
        {object.map(item => [
          <table
            className='checklistsGroupTable'
            key={`${item.id}-0`}
          >
            <thead>
              <tr>
                <th width='auto'>Group Name</th>
                <th width='44px'/>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <TextInput
                    object={item.name}
                    onChange={(k, val) => this.updateItem(item.id, 'name', val)}
                    properties={{
                      type: 'text'
                    }}
                  />
                </td>
                <td>
                  <div
                    className='actionButton'
                    onClick={() => this.deleteItem(item.id)}
                  >
                    <span className='material-icons'>delete</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <ChecklistInput
                    object={item.items}
                    onChange={(k, val) => this.updateItem(item.id, 'items', val)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ])}
        <button className='outline' onClick={this.addItem}>Add item</button>
      </div>
    )
  }

}
