import React from 'react';
import { withRouter } from "react-router-dom";

import '../../sass/components/input/PageLink.scss';

class PageLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  render = () => {
    const properties = this.props.properties;
    let object = this.props.object;
    if (!object) {
      return null;
    }
    const label = object && object[properties.labelKey || 'name'];
    return (
      <div className='pageLink'>
        <span
          onClick={() => {
            if (properties.external) {
              if (object) {
                window.open(object.url || object, '_blank');
              }
            } else {
              object = object || this.props.parentObject;
              this.props.history.push(properties.page.replace(':id', object.id));
            }
          }}
        >{label || object || ''}</span>
      </div>
    )
  }
}

export default withRouter(PageLink);
