import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/CheckboxInput.scss';

export default class CheckboxInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    if (!this.props.disabled) {
      this.props.onChange(this.props.objectKey, value);
    }
  }

  render = () => {
    const object = this.props.object;
    return (
      <div
        className={classnames('checkboxInput', {
          'small': this.props.small,
          'disabled': this.props.disabled,
        })
      }>
        <div
          className={classnames('checkbox', {
            'checked': !!object,
          })}
          onClick={() => this.handleChange(!object)}
        />
      </div>
    )
  }

}
