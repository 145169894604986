import React from 'react';

import '../../sass/components/input/TypeformDataView.scss';

export default class TypeformDataView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render = () => {
    const { definition = {}, answers = [] } = this.props.object || {};
    const question = definition.fields || [];
    if (question.length === 0 || answers.length === 0) {
      return (
        <div className='typeformDataView'>
          <span className='noDataPlaceholder'>No data</span>
        </div>
      )
    }
    return (
      <div className='typeformDataView'>
        <table className='typeformTable'>
          <thead>
            <tr>
              <th width='auto'>Question</th>
              <th width='auto'>Answer</th>
            </tr>
          </thead>
          <tbody>
            {question.map((question, index) =>
              <tr key={index}>
                <td>{question.title}</td>
                <td>{(answers[index] && answers[index][answers[index].type]) || ''}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

}
