import React from 'react';

import FlexibleInput from './FlexibleInput.js';

export default class SearchView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.getDefaultObject(props),
    };
  }

  getDefaultObject = (props) => {
    let object = {};
    const initialValue = this.props.initialValue || {};
    Object.keys(props.config).forEach((key) => {
      if (initialValue[key]) {
        object[key] = {
          value: initialValue[key]
        }
        return;
      }
      const type = props.config[key].type;
      const configDefault = props.config[key].default;
      if (props.defaultValue && props.defaultValue[key] !== undefined) {
        object[key] = {
          value: props.defaultValue[key]
        }
      } else if (type === "text") {
        object[key] = {
          value: configDefault !== undefined ? configDefault : ""
        }
      } else if (type === "checkbox") {
        object[key] = {
          value: !!configDefault,
        }
      } else if (type === "date") {
        object[key] = {
          value: configDefault !== undefined ? configDefault : ""
        }
      } else if (type === "select") {
        object[key] = {
          value: configDefault !== undefined ? configDefault : null
        }
      } else if (type === "multi") {
        object[key] = {
          value: configDefault !== undefined ? configDefault : []
        }
      } else {
        object[key] = {
          value: configDefault !== undefined ? configDefault : null
        }
      }
    });
    return object;
  }

  componentDidMount = () => {
    this.handleSearchClick(true);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.config !== prevProps.config) {
      this.setState({object: this.getDefaultObject(this.props)});
    }
  }

  handleChange = (key, value) => {
    let object = this.state.object;
    object[key].value = value;
    this.setState({object});

    if (this.props.onChange) {
      let search = {};
      let keys = Object.keys(this.state.object);
      for (let i = 0; i < keys.length; i++) {
        search[keys[i]] = this.state.object[keys[i]].value;
      }
      this.props.onChange(search);
    }
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.handleSearchClick(e);
    }
  }

  handleSearchClick = (initial) => {
    let search = {};
    let keys = Object.keys(this.state.object);
    for (let i = 0; i < keys.length; i++) {
      search[keys[i]] = this.state.object[keys[i]].value;
    }
    this.props.onSearch(search, initial === true);
  }

  render = () => {
    return (
      <div className="search-view card" onKeyUp={this.handleKeyUp}>
        {Object.keys(this.props.config).filter(key =>
          !this.props.config[key].showCondition || this.props.config[key].showCondition()
        ).map(key =>
          <div key={key} className='searchField'>
            <label>{this.props.config[key].title}</label>
            <FlexibleInput
              object={this.state.object[key] && this.state.object[key].value}
              onChange={this.handleChange}
              objectKey={key}
              properties={this.props.config[key]}
            />
          </div>
        )}
        {this.props.onSearch ?
          <div className='searchButton'>
            <button onClick={this.handleSearchClick}>Search</button>
          </div>
        : null}
      </div>
    )
  }
}
