import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/SelectOptionPopup.scss';

class SelectOptionPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      title: null,
      options: null,
      buttonText: null,
      callback: null,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('selectOptionPopup', this.selectOptionPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('selectOptionPopup', this.selectOptionPopup);
    }
  }

  selectOptionPopup = (e) => {
    const { title, options, buttonText, callback } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      title,
      options,
      buttonText,
      callback,
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.onSelect(false);
    }
  }

  onSelect = (value) => {
    if (this.props.global) {
      this.state.callback(value);
      this.setState({show: false});
    } else {
      this.props.callback(value);
    }
  }

  render = () => {
    const { show, title, options, buttonText } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'selectOptionPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='popupTitle'>{title}</div>
              <div className='closeButton' onClick={() => this.onSelect(false)}/>
              <div className='optionsList'>
                {(options || []).map(option =>
                  <div
                    key={option.id}
                    className='optionItem'
                    onClick={() => this.onSelect(option.id)}
                  >{option.title}</div>
                )}
              </div>
              <div className='popupControls'>
                <div
                  onClick={() => this.onSelect(true)}
                  className='popupControl confirm'
                >{buttonText || 'Confirm'}</div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default SelectOptionPopup;
