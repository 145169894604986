export default {
  section: "Logs",
  config: {
    method: "audit_logs/",
    title: "Audit logs",
    objectName: "Audit log",
    readOnly: true,
    disableAdd: true,
    search: {
      performed_action: {
        type: "select",
        title: "Action",
        request: "audit_logs/performed_actions",
        clearable: true,
      },
      created_at_min: {
        type: "datetime",
        title: "Date created min",
      },
      created_at_max: {
        type: "datetime",
        title: "Date created max",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
      width: "50px",
    },
    created_at_timestamp: {
      default: "",
      type: "datetime",
      title: "Created time",
      width: "auto",
      showCondition: () => false,
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
    },
    auditable: {
      default: null,
      type: "page-link",
      external: true,
      title: "Subject",
    },
    performed_action: {
      default: "",
      type: "text",
      title: "Action",
      width: "auto",
    },
    user_list_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "User",
      width: "auto",
    },
    changes_list: {
      default: [],
      type: "changes",
      title: "Changes",
    },

  }
};
