import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Segment,
  Form,
  Icon,
  Table,
  Pagination,
  Header,
  Grid,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { sendRequest, triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';
import BulkUploadModal from './shared/BulkUploadModal';

const DEFAULT_PAGE_LIMIT = 10;
const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

function MarketMapCompayListView(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsValueChain, setOptionsValueChain] = useState([]);
  const [optionsValueChainType, setOptionsValueChainType] = useState([]);
  const [searchFields, setSearchFields] = useState({
    search: '',
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
    value_chain_type_id: null,
  });
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [paging, setPaging] = useState({
    page: 1,
    totalPages: 0,
  });
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const loadList = (page = 1) => {
    const { search, market_map_id, value_chain_id, value_chain_type_id } =
      searchFields;
    const params = {
      ...(search && { search }),
      ...(market_map_id && { market_map_id }),
      ...(value_chain_id && { value_chain_id }),
      ...(value_chain_type_id && { value_chain_type_id }),
      page,
      limit: DEFAULT_PAGE_LIMIT,
    };
    setLoadingCompanies(true);
    sendRequest({
      node: true,
      noLoad: true,
      method: 'companies',
      type: 'GET',
      data: params,
      success: (data) => {
        const { count, objects } = data;
        const totalPages = Math.ceil(count / DEFAULT_PAGE_LIMIT);
        setCompanies(objects);
        setLoadingCompanies(false);
        setPaging({
          ...paging,
          totalPages,
        });
      },
      error: (err) => {
        setLoadingCompanies(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnDelete = (id) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this Company?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `companies/${id}`,
              type: 'DELETE',
              success: (data) => {
                triggerEvent('showSnackbar', [
                  { text: 'Company deleted', type: 'success' },
                ]);
                loadList();
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadOptions('value-chains', setOptionsValueChain);
    loadOptions('value-chain-types', setOptionsValueChainType);
    loadList();
  }, []);

  return (
    <Container>
      <Grid>
        <Grid.Column width={8}>
          <Header as="h2">Companies</Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <UploadContainer>
            <a onClick={() => setOpenUploadModal(true)}>
              Bulk Upload Company via CSV+
            </a>
          </UploadContainer>
        </Grid.Column>
      </Grid>
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              label="Company Name"
              placeholder="Search Company by name"
              value={searchFields.search}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  search: value,
                });
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={searchFields.goal_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  goal_id: value,
                  market_map_id: null,
                  value_chain_type_id: null,
                });
                loadOptions('market-maps', { goal_id: value });
                loadOptions('value-chain-types', {
                  goal_id: value,
                });
              }}
            />
            <Form.Select
              label="Market Map"
              placeholder="Select Market Map"
              options={optionsMarketMap}
              clearable
              search
              value={searchFields.market_map_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  market_map_id: value,
                  value_chain_type_id: null,
                });
                loadOptions('value-chain-types', {
                  goal_id: searchFields.goal_id,
                  market_map_id: value,
                });
              }}
            />
            <Form.Select
              label="Value Chain Category"
              placeholder="Select Value Chain Category"
              options={optionsValueChainType}
              clearable
              search
              value={searchFields.value_chain_type_id}
              onChange={(e, { value }) =>
                setSearchFields({ ...searchFields, value_chain_type_id: value })
              }
            />
            <Form.Select
              label="Chain Position"
              placeholder="Select Chain Position"
              options={optionsValueChain}
              clearable
              search
              value={searchFields.value_chain_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  value_chain_id: value,
                  value_chain_type_id: null,
                });
                loadOptions('value-chain-types', {
                  goal_id: searchFields.goal_id,
                  market_map_id: searchFields.market_map_id,
                  value_chain_id: value,
                });
              }}
            />
          </Form.Group>
          <Form.Button positive onClick={() => loadList()}>
            Search
          </Form.Button>
        </Form>
      </Segment>
      <Segment loading={loadingCompanies}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>SDG</Table.HeaderCell>
              <Table.HeaderCell>Market Map Name</Table.HeaderCell>
              <Table.HeaderCell>Value Chain Category</Table.HeaderCell>
              <Table.HeaderCell>Chain Position</Table.HeaderCell>
              <Table.HeaderCell>Company Name</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!loadingCompanies && companies.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="6">
                  No Market Map Companies found
                </Table.Cell>
              </Table.Row>
            )}
            {!loadingCompanies &&
              companies.length > 0 &&
              companies.map((company) => (
                <Table.Row key={company.id}>
                  <Table.Cell>
                    {company.goal_id && company.goal_name && (
                      <Link to={`/categories/${company.goal_id}`}>
                        {company.goal_name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {company.market_map && (
                      <Link to={`/market-maps/${company.market_map.id}`}>
                        {company.market_map.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {company.value_chain_type && (
                      <Link
                        to={`/market-map-categories/${company.value_chain_type.id}`}
                      >
                        {company.value_chain_type.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>{company.value_chain_name}</Table.Cell>
                  <Table.Cell>{company.name}</Table.Cell>
                  <Table.Cell width={1}>
                    <Icon
                      link
                      name="pencil"
                      onClick={() =>
                        props.history.push(
                          `/market-map-companies/${company.id}`
                        )
                      }
                    />
                    <Icon
                      link
                      name="trash"
                      onClick={() => handleOnDelete(company.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <Pagination
                  defaultActivePage={1}
                  siblingRange={2}
                  totalPages={paging.totalPages}
                  onPageChange={(e, { activePage }) => loadList(activePage)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Link to={`/market-map-companies/create`}>
        <div className="createButton">
          <span className="material-icons">add</span>
        </div>
      </Link>
      <BulkUploadModal open={openUploadModal} setOpen={setOpenUploadModal} />
    </Container>
  );
}

export default withRouter(MarketMapCompayListView);
