import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendRequest } from '../helpers/global.js';

import '../sass/components/NotificationsView.scss';

const mapStoreToProps = (store) => {
  return {
    notifications: store.data.notifications || {
      notifications: [],
      unread_count: 0,
    },
  }
};

class NotificationsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount = () => {
    this.checkUndread();
  }

  componentDidUpdate = (prevProps, prevState) => {
  }
  
  checkUndread = () => {
    sendRequest({
      type: 'POST',
      method: 'notifications/check',
      noLoad: true,
      success: (data) => {
        Storage.setData('notifications', {
          notifications: this.props.notifications.notifications.map(item => ({
            ...item,
            checked: true,
          })),
          unread_count: 0,
        });    
      }
    });
  }

  renderNotificationItem = (item, index) => {
    return (
      <div
        key={item.id}
        className={
          classnames({
            'notificationItem': true,
            'unread': !item.checked,
          })
        } 
        onClick={() => {
          if (item.path) {
            this.props.history.push(item.path);
          }
        }}
      >
        <div className='notificationContent'>
          <div className='notificationBody' dangerouslySetInnerHTML={{__html: item.title}}/>
          <div className='notificationDate'>{moment.unix(item.created_at).fromNow()}</div>
        </div>
      </div>
    )
  }


  render = () => {
    let notifications = this.props.notifications;
    return (
      <div className='notificationsView'>

        <div className='card notificationsList'>
          {notifications.notifications.length > 0
            ? notifications.notifications.map(this.renderNotificationItem)
            : <div className='notificationsPlaceholder'>No notifications yet</div>
          }
        </div>

      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(NotificationsView));
