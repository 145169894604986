import React from 'react';
import ReactJson from 'react-json-view'

export default class JsonInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (value) => {
    let object = value.updated_src;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
    return true;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.object !== prevProps.object &&
      typeof this.props.object !== 'undefined' &&
      this.props.object !== null
    ) {
      this.setState({object: this.props.object});
    }
  }

  render = () => {
    let props = {
      src: this.state.object || {},
      collapsed: 2,
      displayObjectSize: false,
      displayDataTypes: false,
      enableClipboard: false,
    }
    if (this.props.disabled) {
      return (
        <ReactJson {...props}/>
      )
    } else {
      return (
        <ReactJson 
          {...props}
          onEdit={(value, some) => {this.handleChange(value)}}
          onAdd={(value) => {this.handleChange(value)}}
          onDelete={(value) => {this.handleChange(value)}}
          onSelect={(value) => {}}
        />
      )
    }
  }
}
