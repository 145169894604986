import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/ContentPopup.scss';

class ContentPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      title: null,
      content: null,
      buttonText: null,
      callback: null,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showContentPopup', this.showContentPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showContentPopup', this.showContentPopup);
    }
  }

  showContentPopup = (e) => {
    const { title, content, buttonText, callback } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      title,
      content,
      buttonText,
      callback,
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.onAction(false);
    }
  }

  onAction = (value) => {
    if (this.props.global) {
      if (value && this.state.callback) {
        if (this.state.callback(value)) {
          this.setState({show: false});
        }
      } else {
        if (this.state.callback) {
          this.state.callback(value);
        }
        this.setState({show: false});
      }
    } else {
      this.props.callback(value);
    }
  }

  render = () => {
    const { show, title, content, buttonText } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'contentPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='popupTitle'>{title}</div>
              <div className='closeButton' onClick={() => this.onAction(false)}/>
              <div className='contentContainer'>
                {content}
              </div>
              <div className='popupControls'>
                <div
                  onClick={() => this.onAction(true)}
                  className='popupControl confirm'
                >{buttonText || 'Confirm'}</div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default ContentPopup;
