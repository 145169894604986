import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import '../../sass/components/input/UserChats.scss';

export default class UserChats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  showChatContent = (id, chatItem, title) => {
    sendRequest({
      method: `/chats/${id}`,
      type: 'GET',
      success: (data) => {
        triggerEvent('showContentPopup', [{
          title,
          content: this.renderChatContent(chatItem, data),
          buttonText: 'Close',
        }]);
      },
      error: (data) => {
      }
    });
  }

  renderChatContent = (chatItem, data) => {
    return (
      <div className='chatContent customScrollBar'>
        {data.messages.map((message, index) => this.renderChatMessage(message, index, chatItem))}
      </div>
    )
  }

  renderChatMessage = (message, index, chatItem) => {
    const urlRegex = RegExp('((?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?)', 'ig');
    const text = message.content ?
      message.content
        .replace(/\n/g, '<br/>')
        .replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
    : null;
    const file = message.file || null;
    const parentId = this.props.parentObject.id
    const parentRole = this.props.properties.side;
    const messageCompareId = message[message.user_id ? 'user_id' : 'business_profile_id'];
    const author = message.user_id
      ? chatItem.chat_participants.find(p => p.user?.id === messageCompareId)
      : chatItem.chat_participants.find(p => p.business_profile?.id === messageCompareId);
    const parentBusinessIds = this.props.parentObject.business_profile_ids || [];
    const owned = (author[parentRole] && messageCompareId === parentId) || parentBusinessIds.includes(messageCompareId);
    return (
      <div
        key={index}
        data-id={message.id}
        className='messageContainer'
      >
        <div
          className={classnames({
            'messageItem': true,
            'owned': owned,
          })}
        >
          <div className='messageInfo'>
            <div className='messageTime'>{moment.unix(message.created_at).format('HH:mm DD.MM.YYYY')}</div>
            <div className='messageAuthor'>
              <span>
                {author.user
                  ? author.user.name
                  : author.business_profile.name
                }
              </span>
            </div>
          </div>
          {text ?
            <div className='messageContent' dangerouslySetInnerHTML={{__html: text}}/>
          : null}
          {file ?
            <div className='fileLink' onClick={() => window.open(file.url, '_blank')}>{file.name}</div>
          : null}
        </div>
      </div>
    )
  }

  renderChatItem = (item) => {
    const chatParticipants = item.chat_participants;
    const participant1 = chatParticipants[0].business_profile || chatParticipants[0].user || {};
    const participant2 = chatParticipants[1].business_profile || chatParticipants[1].user || {};
    const title = `${participant1.name || `#${participant1.id}`} - ${participant2.name || `#${participant2.id}`}`;

    return (
      <div
        key={item.id}
        className='chatItem'
        onClick={() => this.showChatContent(item.id, item, title)}
      >
        {title}
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='userChats'>
        {object.map(this.renderChatItem)}
      </div>
    )
  }

}
