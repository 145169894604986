export default {
    section: "Data",
    config: {
      method: "claim_profiles/",
      title: "Claim/Remove Profiles",
      objectName: "claim_profile",
    //   disableAdd: true,
    },
    properties: {
      id: {
        default: false,
        type: "text",
        title: "#",
        preset: true,
        width: "60px",
      },
      created_at: {
        default: "",
        type: "datetime",
        title: "Created time",
        preset: true,
      },
      updated_at: {
        default: "",
        type: "datetime",
        title: "Modified Time",
        preset: true,
      },
      business_profile_object: {
        type: "page-link",
        page: "/business_profiles/:id",
        title: "Company Name",
        labelKey: "name",
        width: "auto",
        showCondition: (data) => data.id,
        preset: true,
      },
      company_name: {
        type: "text",
        default: "",
        title: "Company Name",
        preset: true,
      },
      company_url: {
        type: "text",
        default: "",
        title: "Company Url",
        preset: true,
      },
      company_id: {
        type: "text",
        default: "",
        title: "Company Id",
        preset: true,
      },
      name: {
        default: "",
        type: "text",
        title: "Name",
        preset: true,
      },
      email: {
        default: "",
        type: "text",
        title: "Email",
        width: "auto",
        preset: true,
      },
      user_status: {
        default: "",
        type: "text",
        title: "User Status",
        width: "auto",
        preset: true,
        disabled: true,
      },
      wish_to: {
        default: "",
        type: "text",
        title: "Request",
        disabled: true,
        // options: [
        //   { id: 'claim_profile', name: 'Claim this profile' },
        //   { id: 'remove_profile', name: 'Remove this profile' }
        // ],
        preset: true,
        width: "auto"
      },
      user_id: {
        default: "",
        type: "text",
        title: "User id",
        preset: true,
      },
      
      detail: {
        default: "",
        type: "textarea",
        title: "Detail",
        preset: true,
        disabled: true,
      },
      status: {
        default: "",
        type: "select",
        title: "Approval Status",
        options: [
          { id: 'approved', name: 'Approved' },
          { id: 'rejected', name: 'Rejected' }
        ],
        width: "auto"
      },
    
    }
  };
  