import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { fileToDataUrl } from '../../../helpers/file_helper';
import { triggerEvent } from '../../../helpers/global';

const maxSize = 3145728; // 3MB
const Container = styled.div`
  margin-top: 10px;
`;
const DropzoneContainer = styled.div`
  border: 1px dashed #aaa;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-tracks: center;
  align-items: center;
  cursor: pointer;

  img {
    max-width: 200px;
  }
`;

function ImageUpload({ imageUrl, toUploadImageUrl, handlerImageAccepted }) {
  const [imageBase64, setImageBase64] = useState(null);
  const [imageUrlUpload, setImageUrlUpload] = useState('');
  const imgRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Clear image upload url
    setImageUrlUpload('');

    const processFiles = async () => {
      if (!!acceptedFiles.length) {
        const file = acceptedFiles[0];
        const base64String = await fileToDataUrl(file);

        setImageBase64(base64String);
        handlerImageAccepted({
          base64String,
          filename: file.name,
          imageUrlUpload: '',
        });
      }
    };
    processFiles();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png,image/svg+xml',
    maxFiles: 1,
    maxSize,
    onDropRejected: (files) => {
      if (files.length && files[0]?.file?.size > maxSize) {
        triggerEvent('showSnackbar', [
          { text: 'Image is larger than 3MB', type: 'error' },
        ]);
      }
    },
  });

  const handleImageUrlInputChange = (_, data) => {
    setImageUrlUpload(data.value);
  };

  // useEffect(() => {
  //   const { complete, naturalHeight } = imgRef.current;

  //   // Based from gist: https://gist.github.com/shuhei/87178387e49948bfa776ca61b69d9e61
  //   console.log({ complete, naturalHeight });
  // }, [imageUrlUpload, imgRef.current]);

  useEffect(() => {
    if (imageUrlUpload.trim()) {
      handlerImageAccepted({
        base64String: '',
        filename: '',
        imageUrlUpload: imageUrlUpload.trim(),
      });
    }
  }, [imageUrlUpload]);

  useEffect(() => {
    if (toUploadImageUrl) {
      setImageUrlUpload(toUploadImageUrl);
    }
  }, [toUploadImageUrl]);

  return (
    <Container>
      <Input
        type="url"
        placeholder="Enter image url"
        value={imageUrlUpload}
        fluid
        onChange={handleImageUrlInputChange}
      />
      <DropzoneContainer {...getRootProps()}>
        {imageUrlUpload && (
          <img ref={imgRef} src={imageUrlUpload} alt="image upload" />
        )}
        {!imageUrlUpload && (imageBase64 || imageUrl) && (
          <img src={imageBase64 ?? imageUrl} alt="image upload" />
        )}
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop image here...</p>
        ) : (
          <p>Drag 'n' drop image here, or click to select image (Max 3MB)</p>
        )}
      </DropzoneContainer>
    </Container>
  );
}

export default ImageUpload;
