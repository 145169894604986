import React from 'react';
import classnames from 'classnames';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import TextInput from '../input/TextInput';

import '../../sass/components/input/InvoicesView.scss';

class InvoicesView extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
    this.amountInput = null;
    this.descriptionInput = null;
  }

  handleChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, value);
    }
  }

  addInvoice = (amount, description) => {
    const properties = this.props.properties;
    const object = this.props.parentObject || this.props.object;
    sendRequest({
      method: properties.method,
      type: 'POST',
      data: {
        sign_up_request_id: object.id,
        amount_in_cents: Math.ceil(Number(amount) * 100),
        description,
      },
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Invoice added', type: 'success'}]);
        if (!this.props.list) {
          const object = [...this.props.object, data];
          this.handleChange(object);
        }
      },
      error: (data) => {
      }
    });
  }

  renderInvoiceForm = () => {
    if (this.amountInput) {
      this.amountInput.value = '';
    }
    if (this.descriptionInput) {
      this.descriptionInput.value = '';
    }
    return (
      <div className='invoiceForm'>
        <label>Amount, $</label>
        <TextInput
          inputRef={input => this.amountInput = input}
          properties={{
            type: 'text'
          }}
        />
        <label>Description</label>
        <TextInput
          inputRef={input => this.descriptionInput = input}
          properties={{
            type: 'textarea'
          }}
        />
      </div>
    )
  }

  showForm = () => {
    triggerEvent('showContentPopup', [{
      title: 'Add invoice',
      content: this.renderInvoiceForm(),
      buttonText: 'Add',
      callback: result => {
        if (result) {
          if (!this.amountInput.value || isNaN(this.amountInput.value)) {
            triggerEvent('showSnackbar', [{text: 'Invalid amount', type: 'error'}]);
            return false;
          } else {
            this.addInvoice(this.amountInput.value, this.descriptionInput.value);
            return true;
          }
        }
      }
    }]);
  }

  render = () => {
    const object = this.props.list ? [] : this.props.object;
    return (
      <div
        className={classnames({
          'invoicesView': true,
          'list': this.props.list,
        })}
      >
        {this.props.list ? null : <>
          <table className='invoicesTable'>
            <thead>
              <tr>
                <th width='120px'>Amount</th>
                <th width='90px'>Status</th>
                <th width='auto'>Description</th>
              </tr>
            </thead>
            <tbody>
              {object.map(item =>
                <tr key={item.id}>
                  <td>${(item.amount_in_cents / 100).toFixed(2)}</td>
                  <td>{item.status}</td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: (item.description || '').replace(/\n/g, '<br/>')
                    }}
                  />
                </tr>
              )}
            </tbody>
          </table>
        </>}
        <button
          className='outline'
          onClick={this.showForm}
        >Add Invoice</button>
      </div>
    )
  }

}

export default InvoicesView;
