import React from 'react';
import classnames from 'classnames';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import appConfig from '../config/';

import '../sass/components/SidebarView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class SidebarView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openedSections: [],
    };
  }

  renderItem = (item) => {
    const page = this.props.location.pathname.substr(1).split('/')[0];
    let selected = page === item.key;
    return (
      <div
        key={item.key}
        className={classnames({
          'listItem': true,
          'selected': selected,
        })}
        onClick={() => {
          if (this.props.onClose) {
            this.props.onClose();
          }
          const path = `/${item.key}`;
          if (path !== this.props.history.location.pathname) {
            this.props.history.push(path);
          }
        }}
      >
        {item.config.title}
      </div>
    )
  }

  renderHeader = (section) => {
    return (
      <div
        key={`h-${section}`}
        className='header'
      >{section}</div>
    )
  }

  renderDivider = (section) => {
    return (
      <div key={`d-${section}`} className='divider'/>
    )
  }

  onContainerClick = (e) => {
    if (e.target === e.currentTarget && this.props.onClose) {
      this.props.onClose()
    }
  }

  render = ()  => {
    const items = Object.keys(appConfig);
    let menuSections = {};
    for (let i = 0; i < items.length; i++) {
      const key = items[i];
      const item = appConfig[key];

      if (!item.config.adminOnly || this.props.user.admin) {
        item.key = key;
        if (!item.section) {
          if (item.rootSection) {
            menuSections[key] = item;
          }
          continue;
        }
        if (!menuSections[item.section]) {
          menuSections[item.section] = {items: []};
        }
        menuSections[item.section].items.push(item);
      }
    }

    let menuItems = [];
    Object.keys(menuSections).forEach(section => {
      if (menuSections[section].items) {
        if (menuItems.length) {
          menuItems.push(this.renderDivider(section))
        }
        menuItems.push(this.renderHeader(section));
        menuSections[section].items.forEach((item) => {
          menuItems.push(this.renderItem(item))
        })
      } else {
        menuItems.push(this.renderItem(menuSections[section]));
      }
    });

    return (
      <div
        className={classnames({
          'sidebarView': true,
          'customScrollBar': true,
          'open': this.props.open
        })}
        onClick={this.onContainerClick}
      >
        <div className='sidebarContainer'>
          <div className='logo'/>
          <div className='menuList'>
            {menuItems}
          </div>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(SidebarView));
