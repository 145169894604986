import React from 'react';
import classnames from 'classnames';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import '../../sass/components/common/SendCalendlyButton.scss';

class SendCalendlyButton extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  sendLink = () => {
    const properties = this.props.properties;
    const object = this.props.parentObject || this.props.object;
    sendRequest({
      type: 'POST',
      method: properties.method.replace(':id', object.id),
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Calendly link sent', type: 'success'}]);
      }
    });
  }

  render = () => {
    return (
      <div
        className={classnames({
          'sendCalendlyButton': true,
          'list': this.props.list,
        })}
      >
        {this.props.list
          ? <>
            <div className='calendlyIcon' onClick={this.sendLink} />
            <div className='tooltip'>Send Calendly link</div>
          </>
          : <button
              className='outline'
              onClick={this.sendLink}
            >Send Calendly link</button>
        }
      </div>
    )
  }

}

export default SendCalendlyButton;
