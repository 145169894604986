import React from 'react';
import { withRouter } from 'react-router-dom';

import '../../sass/components/input/LinksList.scss';

class LinksList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  render = () => {
    const object = (this.props.object || []);
    return (
      <div className='linksList'>
        <div className='listContainer'>
          {object.map(link =>
            <div key={link.id} className='linkItem'>
              <div
                className='linkTitle'
                onClick={() => {
                  if (this.props.properties.page) {
                    const page = this.props.properties.page.replace(':id', link.id);
                    this.props.history.push(page);
                  }
                }}
              >{link.name}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(LinksList);
