import React from 'react';

import TextInput from './TextInput.js';
import WysiwygInput from './WysiwygInput.js';
import SelectInput from './SelectInput.js';

import '../../sass/components/input/ChecklistInput.scss';

const ITEM_TYPES = [
  { id: 'text', name: 'Text' },
  { id: 'file', name: 'File' },
  { id: 'date', name: 'Date' },
  { id: 'info', name: 'Info' },
];

export default class ChecklistInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  updateItem = (itemId, key, value) => {
    const object = this.props.object.map(i =>
      i.id === itemId ? { ...i, [key]: value } : i
    );
    this.handleChange(object);
  }

  addItem = () => {
    const object = [...this.props.object, {
      id: `$${Date.now()}`,
      title: '',
      item_type: null,
      description: '',
    }];
    this.handleChange(object);
  }

  deleteItem = (itemId) => {
    const object = this.props.object.filter(i =>
      i.id !== itemId
    );
    this.handleChange(object);
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='checklistInput'>
        <table className='checklistTable'>
          <thead>
            <tr>
              <th width='auto'>Title</th>
              <th width='160px'>Type</th>
              <th width='44px'/>
            </tr>
          </thead>
          <tbody>
            {object.map(item => [
              <tr key={`${item.id}-1`}>
                <td>
                  <TextInput
                    object={item.title}
                    onChange={(k, val) => this.updateItem(item.id, 'title', val)}
                    properties={{
                      type: 'text'
                    }}
                  />
                </td>
                <td>
                  <SelectInput
                    object={item.item_type}
                    onChange={(k, val) => this.updateItem(item.id, 'item_type', val)}
                    properties={{
                      options: ITEM_TYPES
                    }}
                  />
                </td>
                <td>
                  <div
                    className='actionButton'
                    onClick={() => this.deleteItem(item.id)}
                  >
                    <span className='material-icons'>delete</span>
                  </div>
                </td>
              </tr>,
              <tr key={`${item.id}-2`}>
                <td colSpan={3}>
                  <WysiwygInput
                    object={item.description}
                    onChange={(k, val) => this.updateItem(item.id, 'description', val)}
                  />
                </td>
              </tr>
            ])}
          </tbody>
        </table>
        <button className='outline' onClick={this.addItem}>Add item</button>
      </div>
    )
  }

}
