import React, { useEffect, useState } from 'react';
import { Header, Form, Divider, Modal } from 'semantic-ui-react';
import { useFormik, FormikProvider, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { omit } from 'ramda';

import { triggerEvent, sendRequest } from '../../../helpers/global';
import { loadOptions } from '../../../helpers/market_map';
import CategoryForm from './CategoryForm';
import MarketMapForm from './MarketMapForm';
import ImageUpload from './ImageUpload';

const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
const AddNewLink = styled.a`
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  color: #333;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: #333;
    text-decoration: none;
  }
`;

export const RemoteSubmit = ({ submit, setSubmit }) => {
  const { submitForm, isValid } = useFormikContext();

  useEffect(() => {
    if (submit) {
      submitForm();
    }
  }, [submitForm, submit]);

  useEffect(() => {
    if (submit && !isValid) {
      setSubmit(false);
    }
  }, [setSubmit, isValid, submit]);

  return null;
};

function CompanyForm({
  formValues = null,
  onSubmitHandler,
  renderRemoteSubmit = null,
}) {
  const [optionsCompanyType, setOptionsCompanyType] = useState([]);
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsValueChain, setOptionsValueChain] = useState([]);
  const [optionsValueChainType, setOptionsValueChainType] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [initialValues, setInitialValues] = useState({
    name: '',
    url: '',
    location: '',
    company_type_id: null,
    company_type_name: null,
    goal_id: null,
    goal_name: null,
    market_map_id: null,
    market_map_name: null,
    value_chain_id: null,
    value_chain_name: null,
    value_chain_type_id: null,
    value_chain_type_name: null,
    description: '',
    funding_round: '',
    funding_amount: '',
    market_models: [],
    other_market_model: '',
    business_models: [],
    other_business_model: '',
    logo_base64: '',
    logo_filename: '',
    logo_url: '',
  });
  const [initialValuesMarketMap, setInitialValuesMarketMap] = useState({
    name: '',
    goal_ids: [],
  });
  const [openMarketMap, setOpenMarketMap] = useState(false);
  const [initialValuesValueChainType, setInitialValuesValueChainType] =
    useState({
      name: '',
      goal_id: null,
      market_map_id: null,
      value_chain_id: null,
    });
  const [openValueChainType, setOpenValueChainType] = useState(false);
  const [submittedMarketMap, setSubmittedMarketMap] = useState(null);
  const [submittedValueChainType, setSubmittedValueChainType] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Name is required'),
      url: Yup.string().matches(urlRegex, 'Invalid URL'),
      // company_type_id: Yup.number()
      //   .required('Company Type is required')
      //   .typeError('Company Type is required'),
      // goal_id: Yup.number()
      //   .required('SDG is required')
      //   .typeError('SDG is required'),
      // market_map_id: Yup.number()
      //   .required('Market Map is required')
      //   .typeError('Market Map is required'),
      // value_chain_id: Yup.number()
      //   .required('Chain Position is required')
      //   .typeError('Chain Position is required'),
      // value_chain_type_id: Yup.number()
      //   .required('Value Chain Type is required')
      //   .typeError('Value Chain Type is required'),
      other_market_model: Yup.string().when('market_models', {
        is: (market_models) => market_models.includes('others'),
        then: Yup.string().required('Other Market Model is required'),
      }),
      other_business_model: Yup.string().when('business_models', {
        is: (business_models) => business_models.includes('others'),
        then: Yup.string().required('Other Business Model is required'),
      }),
    }),
    onSubmit: (values) => onSubmitHandler(values),
  });

  const handleOnChangeCheckbox = (e, data) => {
    const { checked, name, value } = data;
    const currentValues = [...formik.values[name]];
    if (checked) {
      formik.setFieldValue(name, [...currentValues, value]);
    } else {
      currentValues.splice(currentValues.indexOf(value), 1);
      formik.setFieldValue(name, currentValues);
    }
  };

  const isCheckboxChecked = (name, value) =>
    formik.values[name].includes(value);

  const handleOnSubmitMarketMap = (values) => {
    sendRequest({
      node: true,
      method: 'market-maps',
      type: 'POST',
      data: values,
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Market Map saved', type: 'success' },
        ]);
        loadOptions('market-maps', setOptionsMarketMap, {
          ...(formik.values.goal_id && { goal_id: formik.values.goal_id }),
        });
        setOpenMarketMap(false);
        setSubmittedMarketMap(data);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnSubmitValueChainType = (values) => {
    sendRequest({
      node: true,
      method: 'value-chain-types',
      type: 'POST',
      data: values,
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Value Chain Category saved', type: 'success' },
        ]);
        loadOptions('value-chain-types', setOptionsValueChainType, {
          ...(formik.values.goal_id && { goal_id: formik.values.goal_id }),
          ...(formik.values.market_map_id && {
            market_map_id: formik.values.market_map_id,
          }),
          ...(formik.values.value_chain_id && {
            value_chain_id: formik.values.value_chain_id,
          }),
        });
        setOpenValueChainType(false);
        setSubmittedValueChainType(data);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleImageUploadSetter = ({
    base64String,
    filename,
    imageUrlUpload,
  }) => {
    formik.setFieldValue('logo_base64', base64String);
    formik.setFieldValue('logo_filename', filename);
    formik.setFieldValue('logo_url', imageUrlUpload);
  };

  useEffect(() => {
    loadOptions('company-types', setOptionsCompanyType);
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadOptions('value-chains', setOptionsValueChain);
    loadOptions('value-chain-types', setOptionsValueChainType);
  }, []);

  useEffect(() => {
    if (formValues) {
      setInitialValues({
        ...omit(['logo'], formValues),
      });
      if (formValues.logo) {
        setLogoUrl(formValues.logo);
      }
    }
  }, [formValues]);

  useEffect(() => {
    const { isValid, isSubmitting } = formik;
    if (!isValid && isSubmitting) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
    }
  }, [formik.isValid, formik.isSubmitting]);

  useEffect(() => {
    if (optionsMarketMap.length && submittedMarketMap) {
      const index = optionsMarketMap.findIndex(
        (value) => +value.value === +submittedMarketMap.id
      );
      if (index !== -1) {
        formik.setFieldValue('market_map_id', +submittedMarketMap.id);
        formik.setFieldValue('market_map_name', submittedMarketMap.name);
        setSubmittedMarketMap(null);
      }
    }
  }, [optionsMarketMap, submittedMarketMap]);

  useEffect(() => {
    if (optionsValueChainType.length && submittedValueChainType) {
      const index = optionsValueChainType.findIndex(
        (value) => +value.value === +submittedValueChainType.id
      );
      if (index !== -1) {
        formik.setFieldValue(
          'value_chain_type_id',
          +submittedValueChainType.id
        );
        formik.setFieldValue(
          'value_chain_type_name',
          submittedValueChainType.name
        );
        setSubmittedValueChainType(null);
      }
    }
  }, [optionsValueChainType, submittedValueChainType]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Header as="h4">Company Information</Header>
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Company Name"
              placeholder="Enter Company Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              autoComplete="off"
              {...(formik.touched.name &&
                formik.errors.name && { error: formik.errors.name })}
            />
            <Form.Input
              name="url"
              label="Company URL"
              placeholder="Enter Company URL"
              value={formik.values.url}
              onChange={formik.handleChange}
              autoComplete="off"
              {...(formik.touched.url &&
                formik.errors.url && { error: formik.errors.url })}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="location"
              label="HQ Location"
              placeholder="Enter HQ Location"
              value={formik.values.location}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <Field
              component={Form.Select}
              name="company_type_id"
              label="Company Type"
              placeholder="Select Company Type"
              options={optionsCompanyType}
              clearable
              search
              value={formik.values.company_type_id}
              onChange={(e, { value, options }) => {
                const company_type_id = !value ? null : +value;
                formik.setFieldValue('company_type_id', company_type_id);
                formik.setFieldValue(
                  'company_type_name',
                  company_type_id
                    ? options.find(
                        (option) => +option.value === company_type_id
                      ).text
                    : null
                );
              }}
              selectOnBlur={false}
              {...(formik.touched.company_type_id &&
                formik.errors.company_type_id && {
                  error: formik.errors.company_type_id,
                })}
            />
          </Form.Group>
          <Divider />
          <Header as="h4">Market Map details</Header>
          <Form.Group>
            <Field
              component={Form.Select}
              name="goal_id"
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={formik.values.goal_id}
              onChange={(e, { value, options }) => {
                const goal_id = !value ? null : +value;
                formik.setFieldValue('goal_id', goal_id);
                formik.setFieldValue(
                  'goal_name',
                  goal_id
                    ? options.find((option) => +option.value === goal_id).text
                    : null
                );
                formik.setFieldValue('market_map_id', null);
                formik.setFieldValue('market_map_name', null);
                formik.setFieldValue('value_chain_type_id', null);
                formik.setFieldValue('value_chain_type_name', null);
                loadOptions('market-maps', setOptionsMarketMap, { goal_id });
                loadOptions('value-chain-types', setOptionsValueChainType, {
                  goal_id: value,
                });

                setInitialValuesMarketMap({
                  ...initialValuesMarketMap,
                  goal_ids: goal_id ? [goal_id] : [],
                });
                setInitialValuesValueChainType({
                  ...initialValuesValueChainType,
                  goal_id,
                  market_map_id: null,
                });
              }}
              selectOnBlur={false}
              width={7}
              {...(formik.touched.goal_id &&
                formik.errors.goal_id && { error: formik.errors.goal_id })}
            />
            <Field
              component={Form.Select}
              name="market_map_id"
              label="Market Map"
              placeholder="Select Market Map"
              options={optionsMarketMap}
              clearable
              search
              value={formik.values.market_map_id}
              onChange={(e, { value, options }) => {
                const market_map_id = !value ? null : +value;
                formik.setFieldValue('market_map_id', market_map_id);
                formik.setFieldValue(
                  'market_map_name',
                  market_map_id
                    ? options.find((option) => +option.value === market_map_id)
                        .text
                    : null
                );
                formik.setFieldValue('value_chain_type_id', null);
                formik.setFieldValue('value_chain_type_name', null);
                loadOptions('value-chain-types', setOptionsValueChainType, {
                  goal_id: formik.values.goal_id,
                  market_map_id,
                });

                setInitialValuesValueChainType({
                  ...initialValuesValueChainType,
                  market_map_id,
                });
              }}
              selectOnBlur={false}
              width={7}
              {...(formik.touched.market_map_id &&
                formik.errors.market_map_id && {
                  error: formik.errors.market_map_id,
                })}
            />
            <Form.Field
              as={() => (
                <AddNewLink onClick={() => setOpenMarketMap(true)}>
                  or Add New
                </AddNewLink>
              )}
              width={2}
            />
          </Form.Group>
          <Form.Group>
            <Field
              component={Form.Select}
              name="value_chain_id"
              label="Chain Position"
              placeholder="Select Chain Position"
              options={optionsValueChain}
              clearable
              search
              value={formik.values.value_chain_id}
              onChange={(e, { value, options }) => {
                const value_chain_id = !value ? null : +value;
                formik.setFieldValue('value_chain_id', value_chain_id);
                formik.setFieldValue(
                  'value_chain_name',
                  value_chain_id
                    ? options.find((option) => +option.value === value_chain_id)
                        .text
                    : null
                );
                formik.setFieldValue('value_chain_type_id', null);
                formik.setFieldValue('value_chain_type_name', null);
                loadOptions('value-chain-types', setOptionsValueChainType, {
                  goal_id: formik.values.goal_id,
                  market_map_id: formik.values.market_map_id,
                  value_chain_id,
                });

                setInitialValuesValueChainType({
                  ...initialValuesValueChainType,
                  value_chain_id,
                });
              }}
              selectOnBlur={false}
              width={7}
              {...(formik.touched.value_chain_id &&
                formik.errors.value_chain_id && {
                  error: formik.errors.value_chain_id,
                })}
            />
            <Field
              component={Form.Select}
              name="value_chain_type_id"
              label="Value Chain Category"
              placeholder="Select Value Chain Category"
              options={optionsValueChainType}
              clearable
              search
              value={formik.values.value_chain_type_id}
              onChange={(e, { value, options }) => {
                const value_chain_type_id = !value ? null : +value;
                formik.setFieldValue(
                  'value_chain_type_id',
                  value_chain_type_id
                );
                formik.setFieldValue(
                  'value_chain_type_name',
                  value_chain_type_id
                    ? options.find(
                        (option) => +option.value === value_chain_type_id
                      ).text
                    : null
                );
              }}
              selectOnBlur={false}
              width={7}
              {...(formik.touched.value_chain_type_id &&
                formik.errors.value_chain_type_id && {
                  error: formik.errors.value_chain_type_id,
                })}
            />
            <Form.Field
              as={() => (
                <AddNewLink onClick={() => setOpenValueChainType(true)}>
                  or Add New
                </AddNewLink>
              )}
              width={2}
            />
          </Form.Group>
          <Form.Group>
            <Form.TextArea
              name="description"
              label="Description"
              placeholder="Enter Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              autoComplete="off"
              rows={7}
              width={16}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="funding_round"
              label="Funding Round"
              placeholder="Enter Funding Round"
              value={formik.values.funding_round}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <Form.Input
              name="funding_amount"
              label="Funding Amount"
              placeholder="Enter Funding Amount"
              value={formik.values.funding_amount}
              onChange={formik.handleChange}
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group grouped>
            <label>Market Model</label>
            <small> (you may select more than one option)</small>
            <Form.Checkbox
              name="market_models"
              label="B2B"
              value="b2b"
              checked={isCheckboxChecked('market_models', 'b2b')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="market_models"
              label="B2B2C"
              value="b2b2c"
              checked={isCheckboxChecked('market_models', 'b2b2c')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="market_models"
              label="B2C"
              value="b2c"
              checked={isCheckboxChecked('market_models', 'b2c')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="market_models"
              label="B2G"
              value="b2g"
              checked={isCheckboxChecked('market_models', 'b2g')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="market_models"
              label="Others"
              value="others"
              checked={isCheckboxChecked('market_models', 'others')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Input
              name="other_market_model"
              placeholder="Enter other market model"
              value={formik.values.other_market_model}
              onChange={formik.handleChange}
              autoComplete="off"
              disabled={!formik.values.market_models.includes('others')}
              width={6}
              {...(formik.touched.other_market_model &&
                formik.errors.other_market_model && {
                  error: formik.errors.other_market_model,
                })}
            />
          </Form.Group>
          <Form.Group grouped>
            <label>Business Model</label>
            <small> (you may select more than one option)</small>
            <Form.Checkbox
              name="business_models"
              label="Services"
              value="services"
              checked={isCheckboxChecked('business_models', 'services')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="business_models"
              label="Hardware"
              value="hardware"
              checked={isCheckboxChecked('business_models', 'hardware')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="business_models"
              label="Software"
              value="software"
              checked={isCheckboxChecked('business_models', 'software')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="business_models"
              label="Business model or financial innovation"
              value="business_financial"
              checked={isCheckboxChecked(
                'business_models',
                'business_financial'
              )}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Checkbox
              name="business_models"
              label="Others"
              value="others"
              checked={isCheckboxChecked('business_models', 'others')}
              onChange={handleOnChangeCheckbox}
            />
            <Form.Input
              name="other_business_model"
              placeholder="Enter other business model"
              value={formik.values.other_business_model}
              onChange={formik.handleChange}
              autoComplete="off"
              disabled={!formik.values.business_models.includes('others')}
              width={6}
              {...(formik.touched.other_business_model &&
                formik.errors.other_business_model && {
                  error: formik.errors.other_business_model,
                })}
            />
          </Form.Group>
          <label style={{ fontWeight: 'bold' }}>Logo</label>
          <ImageUpload
            imageUrl={logoUrl || formik.values.logo_base64}
            handlerImageAccepted={handleImageUploadSetter}
            toUploadImageUrl={formik.values.logo_url}
          />
          {!renderRemoteSubmit && (
            <Form.Button positive type="submit">
              Save
            </Form.Button>
          )}
          {renderRemoteSubmit && renderRemoteSubmit()}
        </Form>
      </FormikProvider>
      <Modal
        onClose={() => setOpenMarketMap(false)}
        onOpen={() => setOpenMarketMap(true)}
        open={openMarketMap}
        size="small"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header size="medium">Add Market Map</Modal.Header>
        <Modal.Content>
          <MarketMapForm
            formValues={initialValuesMarketMap}
            onSubmitHandler={handleOnSubmitMarketMap}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenValueChainType(false)}
        onOpen={() => setOpenValueChainType(true)}
        open={openValueChainType}
        size="small"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header size="medium">Add Value Chain Category</Modal.Header>
        <Modal.Content>
          <CategoryForm
            formValues={initialValuesValueChainType}
            onSubmitHandler={handleOnSubmitValueChainType}
          />
        </Modal.Content>
      </Modal>
    </>
  );
}

export default CompanyForm;
