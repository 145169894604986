export default {
  section: "Data",
  config: {
    method: "comments/",
    title: "Comments",
    objectName: "comments",
    disableAdd: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    content: {
      default: "",
      type: "textarea",
      title: "Content",
      width: "auto",
    },
    user_object: {
      type: "page-link",
      title: "User",
      page: "/users/:id",
      width: "auto",
      labelKey: "name",
      showCondition: object => object.user_object,
    },
    business_profile_object: {
      type: "page-link",
      title: "Business Profile",
      page: "/business_profiles/:id",
      width: "auto",
      labelKey: "name",
      showCondition: object => object.business_profile_object,
    },
    post_object: {
      type: "page-link",
      title: "Post ID",
      page: "/posts/:id",
      width: "auto",
      labelKey: "id",
      showCondition: object => object.post_object,
    },
  }
};
