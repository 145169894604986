import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import {
  Container,
  Segment,
  Header,
  Form,
  Icon,
  Divider,
  Table,
  Image,
  Button,
  Modal,
  Tab,
} from 'semantic-ui-react';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { sendRequest, triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';
import MarketMapExpertForm from './shared/MarketMapExpertForm';

const TableCellAction = styled(Table.Cell)`
  display: flex;
  justify-content: space-evenly;
  width: 100% !important;
`;

function MarketMapInformationFormView(props) {
  const { id } = useParams();
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsMarketMapExpert, setOptionsMarketMapExpert] = useState([]);
  const [initialValues, setInitialValues] = useState({
    goal_id: null,
    market_map_id: null,
    summary: '',
    glossary: '',
  });
  const [openMarketMapExpertFormModal, setOpenMarketMapExpertFormModal] =
    useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      goal_id: Yup.number()
        .required('SDG is required')
        .typeError('SDG is required'),
      market_map_id: Yup.number()
        .required('Market Map is required')
        .typeError('Market Map is required'),
      summary: Yup.string().required('Summary is required'),
      glossary: Yup.string().required('Glossary is required'),
    }),
    onSubmit: (values) => {
      console.log({ values });
      sendRequest({
        node: true,
        method: `informations${id ? `/${id}` : ''}`,
        type: id ? 'PUT' : 'POST',
        data: {
          ...values,
          market_map_expert_ids: marketMapExperts.map((value) => +value.id),
        },
        success: (_) => {
          triggerEvent('showSnackbar', [
            { text: 'Information saved', type: 'success' },
          ]);
          props.history.push('/market-map-informations');
        },
        error: (err) => {
          triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
        },
      });
    },
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [marketMapExperts, setMarketMapExperts] = useState([]);

  const loadData = () => {
    sendRequest({
      node: true,
      method: `informations/${id}`,
      type: 'GET',
      success: (data) => {
        const {
          goal_id,
          market_map_id,
          summary,
          glossary,
          market_map_experts,
        } = data;
        setInitialValues({
          goal_id,
          market_map_id,
          summary,
          glossary,
        });
        setMarketMapExperts(market_map_experts ?? []);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleCloseModal = () => {
    setSelectedIds([]);
    setOpenMarketMapExpertFormModal(false);
  };

  const renderTabSelect = () => (
    <Tab.Pane>
      <Form>
        <Form.Group widths="equal">
          <Form.Select
            label="Market Map Experts"
            placeholder="Select Market Map Experts"
            options={optionsMarketMapExpert}
            clearable
            search
            value={selectedIds}
            multiple
            required
            onChange={(e, { value }) => {
              setSelectedIds(value);
            }}
          />
        </Form.Group>
        <Form.Button
          positive
          disabled={!selectedIds.length}
          onClick={() => handleSelectMarketMapExpert()}
        >
          Select Market Map Expert
        </Form.Button>
      </Form>
    </Tab.Pane>
  );

  const renderTabAdd = () => (
    <Tab.Pane>
      <MarketMapExpertForm onSubmitHandler={handleSubmitMarketMapExpert} />
    </Tab.Pane>
  );

  const handleSelectMarketMapExpert = () => {
    if (selectedIds) {
      const newMarketMapExperts = [];
      selectedIds.forEach((id) => {
        const findIndex = marketMapExperts.findIndex(
          (value) => +value.id === +id
        );
        if (findIndex === -1) {
          const findOption = optionsMarketMapExpert.find(
            (value) => +value.value === +id
          );
          let profile_picture = null;
          if (findOption.data.profile_picture) {
            const s3Data = JSON.parse(findOption.data.profile_picture);
            profile_picture = s3Data.Location;
          }
          newMarketMapExperts.push({ ...findOption.data, profile_picture });
        }
      });
      setMarketMapExperts([...marketMapExperts, ...newMarketMapExperts]);
      handleCloseModal();
    }
  };

  const handleDeleteMarketMapExpert = (index) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this expert?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            const newMarketMapExperts = [
              ...marketMapExperts.slice(0, index),
              ...marketMapExperts.slice(index + 1),
            ];
            setMarketMapExperts(newMarketMapExperts);
          }
        },
      },
    ]);
  };

  const handleSubmitMarketMapExpert = (values) => {
    sendRequest({
      node: true,
      method: 'market-map-experts',
      type: 'POST',
      data: values,
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Market Map Expert saved', type: 'success' },
        ]);
        loadOptions('market-map-experts', setOptionsMarketMapExpert);
        let profile_picture = null;
        if (data.profile_picture) {
          const s3Data = JSON.parse(data.profile_picture);
          profile_picture = s3Data.Location;
        }
        const marketMapExpert = { ...data, profile_picture };

        setMarketMapExperts([...marketMapExperts, marketMapExpert]);
        handleCloseModal();
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadOptions('market-map-experts', setOptionsMarketMapExpert);
    if (id) {
      loadData();
    }
  }, []);

  return (
    <Container>
      <Header as="h2">{`${id ? 'Edit' : 'New'} Information`}</Header>
      <Segment>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group widths="equal">
              <Field
                component={Form.Select}
                name="goal_id"
                label="SDG"
                placeholder="Select SDG"
                options={optionsGoal}
                clearable
                search
                value={formik.values.goal_id}
                onChange={(e, { value }) => {
                  const goal_id = !value ? null : +value;
                  formik.setFieldValue('goal_id', goal_id);
                  formik.setFieldValue('market_map_id', null);
                  loadOptions('market-maps', setOptionsMarketMap, { goal_id });
                }}
                selectOnBlur={false}
                {...(formik.touched.goal_id &&
                  formik.errors.goal_id && { error: formik.errors.goal_id })}
              />
              <Field
                component={Form.Select}
                name="market_map_id"
                label="Market Map"
                placeholder="Select Market Map"
                options={optionsMarketMap}
                clearable
                search
                value={formik.values.market_map_id}
                onChange={(e, { value }) => {
                  const market_map_id = !value ? null : +value;
                  formik.setFieldValue('market_map_id', market_map_id);
                }}
                selectOnBlur={false}
                {...(formik.touched.market_map_id &&
                  formik.errors.market_map_id && {
                    error: formik.errors.market_map_id,
                  })}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="summary"
                label="Summary"
                placeholder="Enter Summary"
                value={formik.values.summary}
                onChange={formik.handleChange}
                autoComplete="off"
                rows={7}
                {...(formik.touched.summary &&
                  formik.errors.summary && { error: formik.errors.summary })}
              />
              <Form.TextArea
                name="glossary"
                label="Glossary"
                placeholder="Enter Glossary"
                value={formik.values.glossary}
                onChange={formik.handleChange}
                autoComplete="off"
                rows={7}
                {...(formik.touched.glossary &&
                  formik.errors.glossary && { error: formik.errors.glossary })}
              />
            </Form.Group>
            <Divider />
            <Header as="h3">
              {'Experts'}
              <Button
                as="a"
                size="mini"
                positive
                style={{ marginLeft: 10 }}
                onClick={() => setOpenMarketMapExpertFormModal(true)}
              >
                Add Expert
              </Button>
            </Header>
            <Table fixed compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Position</Table.HeaderCell>
                  <Table.HeaderCell width={5}>LinkedIn</Table.HeaderCell>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {!marketMapExperts.length && (
                  <Table.Row>
                    <Table.Cell colSpan="5">
                      No Market Map Experts found
                    </Table.Cell>
                  </Table.Row>
                )}
                {!!marketMapExperts.length &&
                  marketMapExperts.map((expert, index) => (
                    <Table.Row key={expert.id}>
                      <Table.Cell>
                        {expert.profile_picture && (
                          <Image src={expert.profile_picture} avatar />
                        )}
                      </Table.Cell>
                      <Table.Cell>{expert.name}</Table.Cell>
                      <Table.Cell>{expert.position}</Table.Cell>
                      <Table.Cell>
                        {expert.linkedin_url && (
                          <a
                            href={expert.linkedin_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {expert.linkedin_url}
                          </a>
                        )}
                      </Table.Cell>
                      <TableCellAction>
                        <Icon
                          link
                          name="trash"
                          onClick={() => handleDeleteMarketMapExpert(index)}
                        />
                      </TableCellAction>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            <Form.Button positive type="submit">
              <Icon name="save" />
              Save
            </Form.Button>
          </Form>
        </FormikProvider>
      </Segment>
      <Modal
        onClose={() => handleCloseModal()}
        onOpen={() => setOpenMarketMapExpertFormModal(true)}
        open={openMarketMapExpertFormModal}
        size="small"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header size="medium">Add Expert</Header>
        <Modal.Content>
          <Tab
            defaultActiveIndex={0}
            panes={[
              {
                menuItem: 'Select from existing Experts',
                render: renderTabSelect,
              },
              {
                menuItem: 'Add New Expert',
                render: renderTabAdd,
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Close</Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default withRouter(MarketMapInformationFormView);
