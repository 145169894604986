import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Amplify from '@aws-amplify/core';

import './config';

import Storage from './helpers/Storage.js';
import App from './App';

import './sass/base.scss';
import './sass/animations.scss';
import 'semantic-ui-css/semantic.min.css';
import './sass/semantic.override.scss';

require('./css/index.css');
require('./css/material-icons.css');
require('./css/react-select.css');
require('react-datetime/css/react-datetime.css');
require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    region: process.env.REACT_APP_AWS_REGION,
  },
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

const init = async () => {
  try {
    await Promise.all([Storage.loadData('user')]);
  } catch (ex) {}
};

init().then(() => {
  ReactDOM.render(
    <Provider store={Storage.getStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('content')
  );
});
