import React from 'react';

import Storage from '../helpers/Storage';
import { sendRequest, triggerEvent } from '../helpers/global.js';
import { signIn } from '../helpers/amplify';

import '../sass/components/LoginView.scss';

export default class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        password: '',
      },
      errors: {},
    };
  }

  loginAction = (user) => {
    localStorage.setItem(
      'thesdg-admin:user_attributes',
      JSON.stringify(user.attributes)
    );
    sendRequest({
      method: 'me',
      type: 'GET',
      success: (data) => {
        Storage.setData('user', data);
        triggerEvent('showSnackbar', [
          { text: 'Success Login', type: 'success' },
        ]);
        triggerEvent('removeLoad');
      },
      error: (data) => {
        triggerEvent('showSnackbar', [
          {
            text: 'Email/password is incorrect',
            type: 'error',
          },
        ]);
        triggerEvent('removeLoad');
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { email, password } = this.state.data;
    const errors = {};
    if (!email) {
      errors.email = 'Please enter Email';
    }
    if (!password) {
      errors.password = 'Please enter Password';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const amplifySignIn = async () => {
      triggerEvent('addLoad');

      const response = await signIn(email, password);

      if (response) {
        this.loginAction(response);
      } else {
        triggerEvent('showSnackbar', [
          {
            text: 'Email/password is incorrect',
            type: 'error',
          },
        ]);
        triggerEvent('removeLoad');
      }
    };
    amplifySignIn();
  };

  renderInput = (
    key,
    title,
    type = 'text',
    required = false,
    disabled = false
  ) => {
    return (
      <div key={key}>
        <label htmlFor={key}>{title}</label>
        <input
          type={type}
          autoComplete={key}
          placeholder={this.state.errors[key] || ''}
          value={this.state.errors[key] ? '' : this.state.data[key]}
          onChange={(e) =>
            this.setState({
              data: { ...this.state.data, [key]: e.target.value },
            })
          }
          onFocus={() =>
            this.setState({ errors: { ...this.state.errors, [key]: null } })
          }
          required={required}
          disabled={disabled}
        />
      </div>
    );
  };

  render = () => {
    return (
      <div className="loginView">
        <div className="loginForm">
          <div className="logo" />
          {/* {this.renderInput('email', 'Email', 'text')}
          {this.renderInput('password', 'Password', 'password')}
          <button
            onClick={this.handleSubmit}
          >Enter with Email</button> */}
          <form onSubmit={this.handleSubmit}>
            {this.renderInput('email', 'Email', 'email', true)}
            {this.renderInput('password', 'Password', 'password', true)}
            <button>Enter with Email</button>
          </form>
        </div>
      </div>
    );
  };
}
