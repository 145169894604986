import React from 'react';

export default class LinkInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    //this.props.onChange(this.props.objectKey, object);
  }

  render = () => {
    if (this.props.object) {
      return (
        <a
          className="view-link"
          href={this.props.object}
          target="_blank"
          rel="noopener noreferrer"
        >{this.props.object}</a>
      )
    } else {
      return null
    }
  }
}
