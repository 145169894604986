export default {
  section: "Data",
  config: {
    method: "banners/",
    title: "Banners",
    objectName: "Banner",
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    page: {
      default: "",
      type: "text",
      title: "Page",
      width: "auto",
    },
    content: {
      default: "",
      type: "wysiwyg",
      title: "Content",
    },
    position: {
      default: "sidebar_top",
      type: "select",
      title: "Position",
      options: [
        { id: 'sidebar_top', name: 'Sidebar Top' },
        { id: 'sidebar_bottom', name: 'Sidebar Bottom' },
        { id: 'content_top', name: 'Content Top' },
        { id: 'content_bottom', name: 'Content Bottom' },
        { id: 'content_middle', name: 'Content Middle' },
        { id: 'mobile_top', name: 'Mobile Top' },
        { id: 'mobile_middle', name: 'Mobile Middle' },
        { id: 'mobile_bottom', name: 'Mobile Bottom' },
      ],
      width: "auto"
    },
  }
};
