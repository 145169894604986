import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/SwitchInput.scss';

export default class SwitchInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  render = () => {
    const object = this.props.object;
    return (
      <div
        className={classnames({
          'switchInput': true,
          'active': !!object
        })}
        onClick={() => this.handleChange(!object)}
      >
        <div className='switcherIndicator'/>
      </div>
    )
  }

}
