import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';

import { sendRequest, formatedAmountString } from '../helpers/global.js';

import '../sass/components/DashboardView.scss';

const MONTHS_OPTIONS = [
  { id: 1, name : '1 month' },
  { id: 3, name : '3 month' },
  { id: 6, name : '6 month' },
  { id: 12, name : '12 month' },
];

const FINANCING_OPTIONS = [
  { id: 'all', name : 'all' },
  { id: 'equity', name : 'equity' },
  { id: 'loan', name : 'loan' },
  { id: 'convertible', name : 'convertible' },
];

const CHART_COLORS = {
  paid: '#3aa097',
  pending: '#ffe21f',
  refunded: '#cdc6ff',
  rejected: '#cdc6ff',
  withdrawn: '#999999',
  error: '#d9499a',
  draft: '#70b0ab',
};

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class DashboardView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: {
        months : MONTHS_OPTIONS[2].id,
        financing_option: FINANCING_OPTIONS[0].id,
      },
      data: null,
    };
  }

  componentDidMount = () => {
    this.requestData();
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  requestData = () => {
    sendRequest({
      method: 'dashboard/',
      type: 'GET',
      data: this.state.search,
      success: (data) => {
        this.setState({
          data: {
            ...data,
            search: this.state.search,
          }
        });
      },
      error: (data) => {
      }
    });
  }

  renderFilter = (key, options, title) => {
    const search = this.state.search;
    return (
      <div className='filterContainer'>
        <div className='filterTitle'>{title}</div>
        {options.map(option =>
          <div
            key={option.id}
            className={classnames('filterItem', {
              'active': this.state.search[key] === option.id
            })}
          >
            <span
              onClick={() => {
                this.setState({
                  search: {...search, [key]: option.id}
                }, this.requestData);
              }}
            >{option.name}</span>
          </div>
        )}
      </div>
    )
  }

  renderLastMonths = () => {
    const data = this.state.data;
    const months = MONTHS_OPTIONS.find(i => i.id === data.search.months).name;
    return (
      <div className='blockMonth'>Last {months}</div>
    )
  }

  renderInvestmentsBlock = () => {
    const data = this.state.data;
    const blockData = data['investments'] || {};
    return (
      <div className='dashboardBlock'>
        <div className='blockContent'>
          <div className='blockTitle'>Investments</div>
          <div className='blockData investments'>
            <div className='dataTotal'>
              <span>{blockData.total}</span>
              <label>new investments</label>
            </div>
            <div className='dataDetails'>
              <div className='detailItem'>${formatedAmountString(blockData.paid)} paid</div>
              <div className='detailItem schedule'>${formatedAmountString(blockData.pending)} pending</div>
              <div className='detailItem cancel'>${formatedAmountString(blockData.rejected)} rejected</div>
            </div>
          </div>
          {this.renderLastMonths()}
        </div>
      </div>
    )
  }

  renderProposalsBlock = () => {
    const data = this.state.data;
    const blockData = data['proposals'] || {};
    return (
      <div className='dashboardBlock'>
        <div className='blockContent'>
          <div className='blockTitle'>Proposals</div>
          <div className='blockData proposals'>
            <div className='dataTotal'>
              <span>{blockData.total}</span>
              <label>new proposals</label>
            </div>
            <div className='dataDetails'>
              <div className='detailItem'>{blockData.raised}% raised</div>
              <div className='detailItem schedule'>${formatedAmountString(blockData.to_be_funded)} to be funded</div>
              <div className='detailItem pause'>{blockData.pending_count} pending</div>
            </div>
          </div>
          {this.renderLastMonths()}
        </div>
      </div>
    )
  }

  renderUserBlock = () => {
    const data = this.state.data;
    const blockData = data['users'] || {};
    return (
      <div className='dashboardBlock'>
        <div className='blockContent'>
          <div className='blockTitle'>Users</div>
          <div className='blockData users'>
            <div className='dataTotal'>
              <span>{blockData.total}</span>
              <label>new users</label>
            </div>
            <div className='dataDetails'>
              <div className='detailItem person'>{blockData.entrepreneurs} entrepreneurs</div>
              <div className='detailItem person'>{blockData.individual_investors} individual investors</div>
              <div className='detailItem person'>{blockData.institutional_investors} institutional investors</div>
            </div>
          </div>
          {this.renderLastMonths()}
        </div>
      </div>
    )
  }

  renderRencentInvestmentsBlock = () => {
    const data = this.state.data;
    const blockData = data['recent_investments'] || [];
    return (
      <div className='dashboardBlock'>
        <div className='blockContent'>
          <div className='blockTitle'>{blockData.length} recent investments</div>
          <div className='blockInvestmentsList'>
            <table>
              <tbody>
                {blockData.map((investment, index) =>
                  <tr key={investment.id}>
                    <td width='20px'>{index + 1}</td>
                    <td width='auto'>
                      <div
                        className='investmentLink'
                        onClick={() => window.open(`/investments/${investment.id}`, '_blank')}
                      >{investment.proposal}</div>
                    </td>
                    <td width='100px'>
                      <div className='investmentAmount'>${formatedAmountString(investment.amount)}</div>
                    </td>
                    <td width='80px'>
                      <div className={`investmentStatus ${investment.status}`}>{investment.status}</div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div
                      className='investmentLink'
                      onClick={() => this.props.history.push('/investments')}
                    >View more</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {this.renderLastMonths()}
        </div>
      </div>
    )
  }

  renderInvestmentsStatusBlock = () => {
    const data = this.state.data;
    const blockData = data['investments_statuses'] || {};
    const keys = Object.keys(blockData).filter(key => key !== 'total');
    return (
      <div className='dashboardBlock wide'>
        <div className='blockContent'>
          <div className='blockTitle'>Investments status</div>
          <div className='blockChart'>
            <Doughnut
              height={110}
              data={{
                labels: keys,
                datasets: [{
                    data: keys.map(key => blockData[key]),
                    backgroundColor: keys.map(key => CHART_COLORS[key] || '#999999'),
                    borderWidth: 0
                }],
              }}
              options={{
                legend: {
                  position: 'right',
                }
              }}
            />
          </div>
          {this.renderLastMonths()}
        </div>
      </div>
    )
  }

  render = () => {
    const user = this.props.user || {};
    if (!this.state.data) {
      return null;
    }
    return (
      <div className='dashboardView'>
        <div className='dashboardTitle'>Welcome, {user.name}!</div>
        {this.renderFilter('months', MONTHS_OPTIONS, 'Report duration:')}
        {this.renderFilter('financing_option', FINANCING_OPTIONS, 'Proposal financing option:')}
        <div className='dashboardContent'>
          {this.renderInvestmentsBlock()}
          {this.renderProposalsBlock()}
          {this.renderUserBlock()}
          {this.renderRencentInvestmentsBlock()}
          {this.renderInvestmentsStatusBlock()}
        </div>
      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(DashboardView));
