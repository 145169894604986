import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import '../sass/components/LoadIndicator.scss';

export default class LoadIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
    };
  }

  render = () => {
    return (
      <div
        className={classnames({
          'loadIndicator': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={this.props.show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div className='overlay'>
            <div className='circularProgress'/>
          </div>
        </CSSTransition>
      </div>
    )
  }

}
