import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Container, Segment, Header } from 'semantic-ui-react';

import { sendRequest, triggerEvent } from '../../helpers/global';
import CategoryForm from './shared/CategoryForm';

function MarketMapCategoryFormView(props) {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: '',
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
  });

  const loadData = () => {
    sendRequest({
      node: true,
      method: `value-chain-types/${id}`,
      type: 'GET',
      success: (data) => {
        const { name, goal_id, market_map_id, value_chain_id } = data;
        setInitialValues({ name, goal_id, market_map_id, value_chain_id });
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnSubmit = (values) => {
    sendRequest({
      node: true,
      method: `value-chain-types${id ? `/${id}` : ''}`,
      type: id ? 'PUT' : 'POST',
      data: values,
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Value Chain Category saved', type: 'success' },
        ]);
        props.history.push('/market-map-categories');
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  return (
    <Container>
      <Header as="h2">{`${id ? 'Edit' : 'New'} Value Chain Category`}</Header>
      <Segment>
        <CategoryForm
          formValues={initialValues}
          onSubmitHandler={handleOnSubmit}
        />
      </Segment>
    </Container>
  );
}

export default withRouter(MarketMapCategoryFormView);
