import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { eventOn, eventOff } from '../helpers/global';
import appConfig from '../config/';

import UserAvatarView from './UserAvatarView';
import SidebarView from './SidebarView.js';
import DropdownMenu from './common/DropdownMenu';
import NotificationsButton from './common/NotificationsButton';

import '../sass/components/HeaderView.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class HeaderView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      historyDepth: 1,
      title: null,
    };
  }

  componentDidMount = () => {
    this.props.history.listen((loc, type) => {
      if (type === 'PUSH') {
        this.setState({historyDepth: this.state.historyDepth + 1, title: null});
      } else if (type === 'POP') {
        this.setState({historyDepth: this.state.historyDepth - 1, title: null});
      } else {
        this.setState({title: null});
      }
    });
    eventOn('setHeaderTitle', this.setHeaderTitle);
  }

  componentWillUnmount = () => {
    eventOff('setHeaderTitle', this.setHeaderTitle);
  }

  setHeaderTitle = (e) => {
    const { title } = e.detail[0];
    this.setState({title});
  }

  getPageTilte = () => {
    if (this.state.title) {
      return this.state.title;
    }
    const key = this.props.location.pathname.substr(1).split('/')[0];
    if (key === 'aggregated') {
      return 'Fundraiser Portal';
    } else if (appConfig[key] && appConfig[key].config) {
      return appConfig[key].config.title;
    } else {
      let title = key.replace(/_/g, ' ');
      return title.substr(0, 1).toUpperCase() + title.substr(1);
    }
  }

  renderOptionsButton = () => {
    return (
      <div className='optionsButton'>
        <UserAvatarView user={this.props.user} size={30}/>
        <DropdownMenu
          anchorRight
          options={[
            {
              title: 'Settings',
              onClick: () => this.props.history.push('/settings'),
            },
            {
              title: 'Sign out',
              onClick: () => this.props.onLogout(),
            },
          ]}
        />
      </div>
    )
  }

  render = () => {
    if (!this.props.user) {
      return null;
    }
    const menuOpen = this.state.menuOpen;
    return (
      <div className='headerView'>
        <span className='menuButton' onClick={() => this.setState({menuOpen: !menuOpen})}>
          <span className='material-icons'>menu</span>
        </span>
        {this.state.historyDepth > 1 ?
          <span className='backLink' onClick={() => this.props.history.goBack()}>
            <span className='material-icons'>keyboard_backspace</span>
          </span>
        : null}
        <div className='pageHeader'>{this.getPageTilte()}</div>
        <SidebarView open={menuOpen} onClose={() => this.setState({menuOpen: false})}/>
        <NotificationsButton/>
        {this.renderOptionsButton()}
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(HeaderView));
