export default {
  section: "Data",
  config: {
    method: "posts/",
    title: "Posts",
    objectName: "posts",
    disableAdd: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    title: {
      default: "",
      type: "text",
      title: "Title",
      width: "auto",
    },
    user_object: {
      type: "page-link",
      title: "User",
      page: "/users/:id",
      width: "auto",
      labelKey: "name",
      showCondition: object => object.user_object,
    },
    business_profile_object: {
      type: "page-link",
      title: "Business Profile",
      page: "/business_profiles/:id",
      width: "auto",
      labelKey: "name",
      showCondition: object => object.business_profile_object,
    },
    content: {
      default: "",
      type: "wysiwyg",
      title: "Content",
    },
    author_title: {
      default: "",
      type: "text",
      title: "Author Title",
      width: "auto",
    },
    image: {
      type: "image",
      title: "Image",
    },
    active: {
      default: "",
      type: "checkbox",
      title: "Active",
      width: "auto",
    },
    goal_ids: {
      type: "multi-select",
      title: "Goals",
      request: "common_data/goals",
    },
  }
};
