import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Container, Segment, Header } from 'semantic-ui-react';
import { omit } from 'ramda';

import { modelSeparator, othersModelSeparator } from '../../helpers/market_map';
import { sendRequest, triggerEvent } from '../../helpers/global';
import CompanyForm from './shared/CompanyForm';

function MarketMapCompanyFormView(props) {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: '',
    url: '',
    location: '',
    company_type_id: null,
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
    value_chain_type_id: null,
    description: '',
    funding_round: '',
    funding_amount: '',
    market_models: [],
    other_market_model: '',
    business_models: [],
    other_business_model: '',
    logo_base64: '',
    logo_filename: '',
  });

  const loadData = () => {
    sendRequest({
      node: true,
      method: `companies/${id}`,
      type: 'GET',
      success: (data) => {
        const {
          name,
          url,
          location,
          company_type_id,
          goal_id,
          market_map_id,
          value_chain_id,
          value_chain_type_id,
          description,
          funding_round,
          funding_amount,
          market_models,
          business_models,
          logo,
        } = data;

        let other_market_model = '';
        let other_business_model = '';
        if (!!market_models && market_models.includes('others')) {
          const marketModelsArr = market_models.split(modelSeparator);
          const othersString = marketModelsArr.find((value) =>
            value.includes('others')
          );
          const othersArr = othersString.split(othersModelSeparator);
          other_market_model = othersArr[1];
        }
        if (!!business_models && business_models.includes('others')) {
          const businessModelsArr = business_models.split(modelSeparator);
          const othersString = businessModelsArr.find((value) =>
            value.includes('others')
          );
          const othersArr = othersString.split(othersModelSeparator);
          other_business_model = othersArr[1];
        }

        setInitialValues({
          name,
          url: url ?? '',
          location: location ?? '',
          company_type_id: company_type_id ? +company_type_id : null,
          goal_id: goal_id ? +goal_id : null,
          market_map_id,
          value_chain_id: value_chain_id ? +value_chain_id : null,
          value_chain_type_id: value_chain_type_id
            ? +value_chain_type_id
            : null,
          description: description ?? '',
          funding_round: funding_round ?? '',
          funding_amount: funding_amount ?? '',
          market_models: !market_models
            ? []
            : market_models
                .split(modelSeparator)
                .map((value) => (value.includes('others') ? 'others' : value)),
          business_models: !business_models
            ? []
            : business_models
                .split(modelSeparator)
                .map((value) => (value.includes('others') ? 'others' : value)),
          other_market_model,
          other_business_model,
          logo_base64: '',
          logo_filename: '',
          logo,
        });
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnSubmit = (values) => {
    const market_models = values.market_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_market_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    const business_models = values.business_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_business_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    sendRequest({
      node: true,
      method: `companies${id ? `/${id}` : ''}`,
      type: id ? 'PUT' : 'POST',
      data: {
        ...omit(
          [
            'goal_id',
            'other_market_model',
            'other_business_model',
            'value_chain_id',
            'company_type_name',
            'goal_name',
            'market_map_name',
            'value_chain_name',
            'value_chain_type_name',
          ],
          values
        ),
        market_models,
        business_models,
      },
      success: (_) => {
        triggerEvent('showSnackbar', [
          { text: 'Company saved', type: 'success' },
        ]);
        props.history.push('/market-map-companies');
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  return (
    <Container>
      <Header as="h2">{`${id ? 'Edit' : 'New'} Market Map Company`}</Header>
      <Segment>
        <CompanyForm
          formValues={initialValues}
          onSubmitHandler={handleOnSubmit}
        />
      </Segment>
    </Container>
  );
}

export default withRouter(MarketMapCompanyFormView);
