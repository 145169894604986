import React from 'react';
import classnames from 'classnames';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/Snackbar.scss';

const SHOW_TIME = 3000;

class Snackbar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      text: null,
      type: null,
    };
    this.timeout = null;
  }

  componentDidMount = () => {
    eventOn('showSnackbar', this.showSnackbar);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
    eventOff('showSnackbar', this.showSnackbar);
  };

  showSnackbar = (e) => {
    clearTimeout(this.timeout);
    const { text, type = null } = e.detail[0];
    this.setState({ show: true, text, type });
    this.timeout = setTimeout(this.onClose, SHOW_TIME);
  };

  onClose = () => {
    clearTimeout(this.timeout);
    this.setState({ show: false });
  };

  render = () => {
    const { show, text, type } = this.state;
    return (
      <div
        className={classnames({
          snackbar: true,
          show: show,
          [type]: !!type,
        })}
      >
        <div className="snackbarOverlay" />
        <div className="snackbarContainer">
          <div
            className="snackbarText"
            dangerouslySetInnerHTML={{
              __html: text || (type === 'success' ? 'Success!' : 'Error!'),
            }}
          />
          <div onClick={this.onClose} className="snackbarClose" />
        </div>
      </div>
    );
  };
}

export default Snackbar;
