import React from 'react';

import '../../sass/components/input/ImageInput.scss';

export default class ImageInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: props.object,
    };
    this.input = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.object !== this.props.object &&
      typeof this.props.object === 'string'
    ) {
      this.setState({preview: this.props.object});
    }
  }

  handleChange = (file) => {
    this.setState({preview: URL.createObjectURL(file)});
    this.props.onChange(this.props.objectKey, file);
  }

  componentDidMount = () => {
  }

  render = () => {
    return (
      <div className='imageInput'>
        <input
          type='file'
          accept='image/x-png,image/gif,image/jpeg'
          ref={input => this.input = input}
          onChange={e => this.handleChange(e.target.files[0])}
        />
        {this.state.preview ?
          <img alt='' src={this.state.preview}/>
        : null}
        {this.props.disabled ? null :
          <button
            className='outline'
            onClick={() => this.input.click()}
          >Select</button>
        }
      </div>
    )
  }
}
