import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { withRouter } from "react-router-dom";

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import SearchView from './SearchView';
import ObjectListElem from './ObjectListElem';
import Pagination from './common/Pagination';
import CsvDownloader from 'react-csv-downloader';
import '../sass/components/ObjectListView.scss';

const ITEMS_PER_PAGE = 20;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};
class ObjectListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
    this.inputOpenFileRef = React.createRef();
    this.inputbusinessFileRef = React.createRef();
  }

  getDefaultState = (props) => {
    const properties = appConfig[props.configKey].properties;
    const config = appConfig[props.configKey].config;
    let search = {};
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    this.props.history.location.search.replace(/^\?/, '').split('&').forEach(p => {
      const pair = p.split('=');
      if (pair[0]) {
        search[pair[0]] = pair[1] || true;
      }
    });
    const page = (Number(this.props.location.hash.replace(/\D/g, '')) || 1) - 1;
    return {
      objects: [],
      properties,
      config,
      options: {},
      search,
      page,
      maxPage: 0,
    };
  }

  componentDidMount = () => {
    if (!this.state.config.search) {
      this.requestData();
      if(this.state.config.objectName == "business_profiles"){
        this.businessprofileData()
      }
    }
    this.requestOptions();
    this.userData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState(this.getDefaultState(this.props), () => {
        this.requestData();
        this.requestOptions();
        if (this.props.configKey == "users"){
          this.userData();
        }
        if (this.props.configKey=='business_profiles'){
          this.businessprofileData()
        }
      })
    }
  }

  requestOptions = () => {
    let options = Object.assign({}, this.state.options);
    Object.keys(this.state.properties).forEach(key => {
      if (this.state.properties[key].width && this.state.properties[key].type === "select") {
        if (this.state.properties[key].request) {
          sendRequest({
            type: "GET",
            node: !!this.state.config.node,
            method: this.state.properties[key].request,
            success: (data) => {
              if (this.state.properties[key]) {
                const id = this.state.properties[key].option_id_key || 'id';
                const name = this.state.properties[key].option_id_name || 'name';
                const options = data.objects.map((obj) => ({
                  id: obj[id],
                  name: obj[name],
                }));
                this.setState({options:
                  update(this.state.options, {
                    [key]: {$set: options}
                  })
                });
              } else {
                this.setState({options:
                  update(this.state.options, {
                    [key]: {$set: data}
                  })
                });
              }
            },
            error: (data) => {}
          });
        } else if (this.state.properties[key].options) {
          options[key] = this.state.properties[key].options;
        }
      }
    });
    this.setState({options});
  }

  requestData = () => {
    const search = this.state.search;
    const searchParams = Object.keys(search)
      .filter(key => key && search[key] !== null && search[key] !== undefined && search[key] !== '')
      .map(key => `${key}=${search[key]}`)
      .join('&');
    this.props.history.replace(`${this.props.location.pathname}?${searchParams}#${this.state.page + 1}`);
    sendRequest({
      node: !!this.state.config.node,
      method: this.state.config.method,
      data: {
        paginate: true,
        offset: this.state.page * ITEMS_PER_PAGE,
        ...search,
      },
      type: "GET",
      success: (data) => {
        const page = this.state.page;
        const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
        this.setState({
          objects: data.objects,
          maxPage: maxPage,
          page: Math.min(page, maxPage),
        });
      },
      error: (data) => {
      }
    });
  }

  userData = () => {
    const usersList=[];
     sendRequest({
      method: this.state.config.method+"csv_export",
      type: "GET",
      success: (data) => {
        this.setState({
          usersList: data
        });
      },
      error: (data) => {
      }
    });
  }

  businessprofileData = () => {
    const businessProfilesList=[];
     sendRequest({
      method: this.state.config.method+"csv_export",
      type: "GET",
      success: (data) => {
        this.setState({
          businessProfilesList: data
        });
      },
      error: (data) => {
      }
    });
  }

  onObjectDelete = (object) => {
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${this.state.config.objectName} #${object.id}?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm) {
          this.onDeleteConfirm(object);
        }
      }
    }]);
  }
  fileSelectedHandler = (event) => {
    console.log(event.target.files[0]);
    this.setState({
        selectedFile: event.target.files[0]
    }, () => this.fileUploadHandler());
  };
  onDeleteConfirm = (object) => {
    sendRequest({
      node: !!this.state.config.node,
      method: this.state.config.method + object.id,
      type: "DELETE",
      success: (data) => {
        this.requestData(this.state);
      },
      error: (data) => {
      }
    });
  }

  onSearch = (search, initial) => {
    let data = this.state.search;
    Object.keys(search).forEach(key => {
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
      } else {
        data[key] = search[key];
      }
    });
    this.setState({page: initial ? this.state.page : 0, search: data}, () => {
      this.requestData();
    });
  }

  onObjectEdit = (object) => {
    this.props.history.push(`/${this.props.configKey}/${object.id}`);
  }

  onObjectCreate = (e) => {
    this.props.history.push(`/${this.props.configKey}/create`);
  }
  onChangeHandler=(e)=>{
  const formData = new FormData()
  formData.append("user_list", e.target.files[0]);
    console.log(e.target.files)
    sendRequest({
      method: this.state.config.method+"csv_import",
      type: "POST",
      formData,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'The csv upload is in process, it may take some time to reflect here. Please check later. Thanks!', type: 'success'}]);
      },
      error: (data) => {
      }
    });

}
onBusinessChangeHandler=(e)=>{
  const formData = new FormData()
  formData.append("business_list", e.target.files[0]);
    console.log(e.target.files)
    sendRequest({
      method: this.state.config.method+"csv_import",
      type: "POST",
      formData,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'The csv upload is in process, it may take some time to reflect here. Please check later. Thanks!', type: 'success'}]);
      },
      error: (data) => {
      }
    });

}

  onObjectChange = (id, key, value) => {
    const index = this.state.objects.findIndex(i => i.id === id);
    this.setState({objects:
      update(this.state.objects, {
        [index]: {
          [key]: {$set: value}
        }
      })
    });
  }

  renderListColumn = (key) => {
    let columnProps = this.state.properties[key];
    return <th key={key} width={columnProps.width}>
      {this.state.properties[key].title}
    </th>
  }

  renderPagination = () => {
    if (this.state.maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.page + 1}
        maxPage={this.state.maxPage}
        onPageChange={page => {
          this.setState({page: page - 1}, this.requestData);
        }}
      />
    )
  }

  renderSearch = () => {
    if (this.state.config.search) {

      return (
        <>
        <SearchView
          initialValue={this.state.search}
          config={this.state.config.search}
          onSearch={this.onSearch}
        />
        </>
      )
    }
    return null;
  }

showOpenFileDlg = () => {
    this.inputOpenFileRef.current.click()
}
showOpenBusinessFileDlg = () => {
  this.inputbusinessFileRef.current.click()
}
exportListDownload = () =>{
  triggerEvent('showSnackbar', [{text: 'CSV Download Successfully!', type: 'success'}]);
}
  renderImportButton = () => {
    return (
      <>
      {this.state.config.method == "users/" ?
        <label className='userExportButton'>
           <input ref={this.inputOpenFileRef} type="file" onChange={this.onChangeHandler} accept=".csv" style={{ display: "none" }}/>
                <button onClick={this.showOpenFileDlg}>Upload VIA CSV</button>
      </label>
       : ""}
      </>
    )
  }
  renderDowloadButton = () => {
    const user_columns = [{
      id: 'id',
      displayName: 'Id'
    }, {
      id: 'user_email',
      displayName: 'Email'
    }, {
      id: 'name',
      displayName: ' Name'
    }, {
      id: 'website',
      displayName: 'Website'
    }, {
      id: 'city',
      displayName: 'City'
    },  {
      id: 'facebook_url',
      displayName: 'Facebook Url'
    }, {
      id: 'instagram_url',
      displayName: 'IInstagram URL'
    }, {
      id: 'twitter_url',
      displayName: 'Twitter URL'
    }, {
      id: 'linkedin_url',
      displayName: 'Linkedin URL'
    }, {
      id: 'country_name',
      displayName: 'Country Name'
    }, {
      id: 'profile_type',
      displayName: 'Profile Type'
    }, {
      id: 'objectives_title',
      displayName: 'Objectives Title'
    }, {
      id: 'goals_name',
      displayName: 'Goals Name'
    }];
    return (
      <>
      {this.state.config.method == "users/" ?
        <label className='userExportButton'  onClick={this.exportListDownload}>
        <CsvDownloader
        filename="User List"
        extension=".csv"
        columns={user_columns}
        datas={this.state.usersList}
        text="Export VIA CSV"
        />
      </label>
       : ""}
      </>
    )
  }

  renderDowloadCSVButton = () => {
    const user_columns = [
    {
      id: 'email',
      displayName: 'Email'
    }, {
      id: 'password',
      displayName: 'Password'
    }, {
      id: 'first_name',
      displayName: 'First Name'
    }, {
      id: 'last_name',
      displayName: 'Last Name'
    },  {
      id: 'facebook_url',
      displayName: 'Facebook Url'
    }, {
      id: 'instagram_url',
      displayName: 'IInstagram URL'
    }, {
      id: 'twitter_url',
      displayName: 'Twitter URL'
    }, {
      id: 'linkedin_url',
      displayName: 'Linkedin URL'
    }, {
      id: 'Website',
      displayName: 'Website'
    }, {
      id: 'profile_type',
      displayName: 'Profile Type'
    }, {
      id: 'Admin',
      displayName: 'Admin'
    }, {
      id: 'Bio',
      displayName: 'Bio'
    }];
    const userdatas = [{
      email: 'example@gmail.com',
      password: 'test1234',
      first_name: 'First Name',
      last_name: 'Last Name',
      facebook_url: 'Facebook.com',
      instagram_url: 'Instagram.com',
      linkedin_url: 'Linkedin.com',
      Website: 'Website',
      Admin: 'false',
      Bio: 'Facebook.com',
    }];

    return (
      <>
      {this.state.config.method == "users/" ?
        <label className='userExportButton' onClick={this.exportListDownload}>
        <CsvDownloader
        filename="Individual Profile"
        extension=".csv"
        columns={user_columns}
        datas={userdatas}
        text="Download CSV template"
        />
      </label>
       : ""}
      </>
    )
  }

  renderBusniessImportButton = () => {
    return (
      <>
      {this.state.config.method == "business_profiles/" ?
        <label className='userExportButton'>
           <input ref={this.inputbusinessFileRef} type="file" onChange={this.onBusinessChangeHandler} accept=".csv" style={{ display: "none" }}/>
                <button onClick={this.showOpenBusinessFileDlg}>Upload VIA CSV</button>
      </label>
       : ""}
      </>
    )
  }

  rendeBusniessDowloadButton = () => {
    const columns = [{
      id: 'id',
      displayName: 'Id'
    }, {
      id: 'user_email',
      displayName: 'User Email'
    }, {
      id: 'name',
      displayName: 'Name'
    }, {
      id: 'website',
      displayName: 'Website'
    }, {
      id: 'city',
      displayName: 'City'
    }, {
      id: 'youtube_url',
      displayName: 'Youtube URL'
    }, {
      id: 'facebook_url',
      displayName: 'Facebook Url'
    }, {
      id: 'instagram_url',
      displayName: 'IInstagram URL'
    }, {
      id: 'twitter_url',
      displayName: 'Twitter URL'
    }, {
      id: 'linkedin_url',
      displayName: 'Linkedin URL'
    }, {
      id: 'country_name',
      displayName: 'Country Name'
    }, {
      id: 'published',
      displayName: 'Published'
    }, {
      id: 'profile_type',
      displayName: 'Profile Type'
    }, {
      id: 'goals_name',
      displayName: 'Goals Name'
    }, {
      id: 'objectives_title',
      displayName: 'Objectives Title'
    }];
    return (
      <>
      {this.state.config.method == "business_profiles/" ?
        <label className='userExportButton' onClick={this.exportListDownload}>
        <CsvDownloader
        filename="Business profiles List"
        extension=".csv"
        columns={columns}
        datas={this.state.businessProfilesList}
        text="Export VIA CSV"
        />
      </label>
       : ""}
      </>
    )
  }

  rendeBusniessCSVButton = () => {
    const columns = [
    {
      id: 'name',
      displayName: 'name'
    },{
      id: 'description',
      displayName: 'description'
    }, {
      id: 'website',
      displayName: 'website'
    }, {
      id: 'city',
      displayName: 'city'
    }, {
      id: 'youtube_url',
      displayName: 'youtube_url'
    }, {
      id: 'facebook_url',
      displayName: 'facebook_url'
    }, {
      id: 'instagram_url',
      displayName: 'instagram_url'
    }, {
      id: 'twitter_url',
      displayName: 'twitter_url'
    }, {
      id: 'linkedin_url',
      displayName: 'linkedin_url'
    }, {
      id: 'country_name',
      displayName: 'country_name'
    }, {
      id: 'profile_type',
      displayName: 'profile_type'
    },{
      id: 'user_email',
      displayName: 'user_email'
    }, {
      id: 'objective_title',
      displayName: 'objective_title'
    }];
    const businessdatas = [{
      user_email: 'example@gmail.com',
      password: 'test1234',
      name: 'Business Name',
      description: 'Description',
      city: 'city',
      facebook_url: 'Facebook.com',
      instagram_url: 'Instagram.com',
      linkedin_url: 'Linkedin.com',
      Website: 'Website',
      country_name: 'Pakistan',
      profile_type: 'Investor',
      published: 'true',
      objective_title: 'investor'
    }];
    return (
      <>
      {this.state.config.method == "business_profiles/" ?
        <label className='userExportButton' onClick={this.exportListDownload}>
        <CsvDownloader
        filename="Business Profile"
        extension=".csv"
        columns={columns}
        datas={businessdatas}
        text="Download CSV Template"
        />
      </label>
       : ""}
      </>
    )
  }


  renderObjects = () => {
    return (
      <>
      {this.state.objects.map(object =>

        <ObjectListElem
          key={object.id}
          configKey={this.props.configKey}
          onDelete={this.onObjectDelete}
          onEdit={this.onObjectEdit}
          onChange={this.onObjectChange}
          options={this.state.options}
          object={object}
        />
      )}
      </>
    )
  }

  render = () => {
    let config = this.state.config;

    // Objects
    let columns = Object.keys(this.state.properties)
      .filter(key => !!this.state.properties[key].width)
      .filter(key => {
        let condition = this.state.properties[key].listCondition;
        return !condition || condition()
      })
      .map(this.renderListColumn);

    let editColumn = <th width="44px"></th>;
    let deleteColumn = <th width="44px"></th>;
    let addButton = <div
      className='createButton'
      onClick={this.onObjectCreate}
    >
      <span className='material-icons'>add</span>
    </div>
    if (config.modifyCondition && !config.modifyCondition()) {
      addButton = null;
      editColumn = null;
      deleteColumn = null;
    }
    if (config.hideActions) {
      addButton = null;
      editColumn = null;
      deleteColumn = null;
    }
    if (config.disableAdd) {
      addButton = null;
    }
    if (config.disableDelete) {
      deleteColumn = null;
    }

    return (
      <div className='objectListView'>
        {this.renderSearch()}

        <div className='userExportButton'>
        {this.renderImportButton()}
        {this.renderDowloadButton()}
        {this.renderDowloadCSVButton()}
        {this.renderBusniessImportButton()}
        {this.rendeBusniessDowloadButton()}
        {this.rendeBusniessCSVButton()}
        </div>
        {this.props.isMobile
          ? this.renderObjects().map((elem, index) =>
            <div key={index} className='card'>{elem}</div>
          )
          : <div className='card'>
            <table className='objectsListTable'>
              <thead>
                <tr>
                  {columns}
                  {editColumn}
                  {deleteColumn}
                </tr>
              </thead>
              <tbody>
                {this.renderObjects()}
              </tbody>
            </table>
          </div>
        }
        {this.renderPagination()}
        {addButton}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(ObjectListView));
