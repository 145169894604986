export default {
  section: "Data",
  config: {
    method: "subcategories/",
    title: "Subcategories",
    objectName: "Subcategory",
    disableAdd: false,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    category_object: {
      default: false,
      type: "page-link",
      page: "/categories/:id",
      title: "Category",
      labelKey: "name",
      width: "auto",
      showCondition: (data) => data.id,
    },
    category_id: {
      default: null,
      type: "select",
      title: "Category",
      request: "common_data/categories",
      // showCondition: (data) => data.id,
    },
  }
};
