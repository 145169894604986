import React, { useEffect, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import { triggerEvent } from '../../../helpers/global';
import { loadOptions } from '../../../helpers/market_map';

function CategoryForm({ formValues = null, onSubmitHandler }) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsValueChain, setOptionsValueChain] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Name is required'),
      goal_id: Yup.number()
        .required('SDG is required')
        .typeError('SDG is required'),
      market_map_id: Yup.number()
        .required('Market Map is required')
        .typeError('Market Map is required'),
      value_chain_id: Yup.number()
        .required('Chain Position is required')
        .typeError('Chain Position is required'),
    }),
    onSubmit: (values) => onSubmitHandler(values),
  });

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadOptions('value-chains', setOptionsValueChain);
  }, []);

  useEffect(() => {
    if (formValues) {
      setInitialValues(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    const { isValid, isSubmitting } = formik;
    if (!isValid && isSubmitting) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
    }
  }, [formik.isValid, formik.isSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            name="name"
            label="Value Chain Category"
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
            {...(formik.touched.name &&
              formik.errors.name && { error: formik.errors.name })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            component={Form.Select}
            name="goal_id"
            label="SDG"
            placeholder="Select SDG"
            options={optionsGoal}
            clearable
            search
            value={formik.values.goal_id}
            onChange={(e, { value }) => {
              const goal_id = !value ? null : +value;
              formik.setFieldValue('goal_id', goal_id);
              formik.setFieldValue('market_map_id', null);
              loadOptions('market-maps', setOptionsMarketMap, { goal_id });
            }}
            selectOnBlur={false}
            {...(formik.touched.goal_id &&
              formik.errors.goal_id && { error: formik.errors.goal_id })}
          />
          <Field
            component={Form.Select}
            name="market_map_id"
            label="Market Map"
            placeholder="Select Market Map"
            options={optionsMarketMap}
            clearable
            search
            value={formik.values.market_map_id}
            onChange={(e, { value }) => {
              const market_map_id = !value ? null : +value;
              formik.setFieldValue('market_map_id', market_map_id);
            }}
            selectOnBlur={false}
            {...(formik.touched.market_map_id &&
              formik.errors.market_map_id && {
                error: formik.errors.market_map_id,
              })}
          />
          <Field
            component={Form.Select}
            name="value_chain_id"
            label="Chain Position"
            placeholder="Select Chain Position"
            options={optionsValueChain}
            clearable
            search
            value={formik.values.value_chain_id}
            onChange={(e, { value }) => {
              const value_chain_id = !value ? null : +value;
              formik.setFieldValue('value_chain_id', value_chain_id);
            }}
            selectOnBlur={false}
            {...(formik.touched.value_chain_id &&
              formik.errors.value_chain_id && {
                error: formik.errors.value_chain_id,
              })}
          />
        </Form.Group>
        <Form.Button positive type="submit">
          <Icon name="save" />
          Save
        </Form.Button>
      </Form>
    </FormikProvider>
  );
}

export default CategoryForm;
