export const fileToDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const dataUrlToBlob = (dataUrl) => {
  /*
   * base from: https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata#answer-5100158
   */
  var byteString;
  if (dataUrl.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataUrl.split(',')[1]);
  else byteString = unescape(dataUrl.split(',')[1]);

  // separate out the mime component
  var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const downloadBlob = (blob, filename = 'download') => {
  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', filename);

  // Performing a download with click
  a.click();
};
