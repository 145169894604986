import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../helpers/global.js';

import '../sass/components/DocumentsViewItem.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class DocumentsViewItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      fileName: '',
    }
    this.fileNameInput = null;
  }

  componentDidMount = () => {
  }

  onDelete = () => {
    const object = this.props.object;
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${this.props.title} #${object.id}?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm && this.props.onDelete) {
          this.onDeleteConfirm();
        }
      }
    }]);
  }

  onDeleteConfirm = () => {
    const object = this.props.object;
    sendRequest({
      method: this.props.method + object.id,
      type: 'DELETE',
      success: (data) => {
        this.props.onDelete();
        triggerEvent('showSnackbar', [{text: `${this.props.title} deleted`, type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  onChange = (key, value) => {
    const object = this.props.object;
    sendRequest({
      method: this.props.method + object.id,
      type: 'PUT',
      data: {
        [key]: value
      },
      success: (data) => {
        if (key === 'name') {
          this.props.onChange({
            ...object,
            name: data.name,
            file_url: data.file_url,
          });
        } else {
          this.props.onChange({...object, [key]: value});
        }
        triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  renderDate = () => {
    return (
      moment(this.props.object.created_at).format('DD.MM.YYYY HH:mm')
    )
  }

  renderThumbnail  = () => {
    const { name }  = this.props.object;
    const url = () => {
      const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i;
      const type = name.match(pattern);
      return `/images/icons/${type[1]}.png`
    };

    return (
      <div className='thumbnail' style={{backgroundImage: `url(${url()})`}}/>
    )
  }

  onClickDocument = (object) => {
    window.open(object.file_url, '_blank');
  }

  renderFile = () => {
    const object = this.props.object;

    const extention = object.name.substr(object.name.lastIndexOf('.'));

    if (this.state.edit) {
      return (
        <div className='fileCell'>
          <input
            type='text'
            value={this.state.fileName}
            onChange={e => this.setState({fileName: e.target.value})}
            ref={elem => this.fileNameInput = elem}
          />
          <div
            className='actionButton'
            onClick={() => this.setState({edit: false})}
          >
            <span className='material-icons'>close</span>
          </div>
          <div
            className='actionButton'
            onClick={() => {
              this.setState({edit: false});
              this.onChange('name', this.state.fileName + extention);
            }}
          >
            <span className='material-icons'>save</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='fileCell'>
          <div
            className='pageLink'
            onClick={() => {
              this.onClickDocument(object)
            }}
          >{object.name}</div>
          {this.renderDelete()}
        </div>
      )
    }
  }

  renderDelete = () => {
    return (
      <div className='actionButton' onClick={() => this.onDelete()}>
        <span className='material-icons'>delete</span>
      </div>
    )
  }

  renderDesktop = () => {
      return (
        <tr className='documentsViewItem'>
          <td>{this.renderDate()}</td>
          <td>
            <div className='documentClickable'>
              {this.renderThumbnail()}
              {this.renderFile()}
            </div>
          </td>
        </tr>
      )
  }

  renderMobile = () => {
    return (
      <div
        className={classnames({
          'documentsViewItem': true,
          'embedded': this.props.embedded,
          'card': true,
        })}
      >
        <table>
          <tbody>
            <tr>
              <td width='125px'>Creation time</td>
              <td>{this.renderDate()}</td>
            </tr>
            <tr>
              <td width='125px'>File</td>
              <td>{this.renderFile()}</td>
            </tr>
            <tr>
              <td width='125px'>Actions</td>
              <td style={{padding: 0}}>{this.renderDelete()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render = () => {
    if (this.props.isMobile) {
      return this.renderMobile();
    } else {
      return this.renderDesktop();
    }
  }
}

export default connect(mapStoreToProps)(withRouter(DocumentsViewItem));
