import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Segment,
  Form,
  Icon,
  Table,
  Pagination,
  Header,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { sendRequest, triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';

const DEFAULT_PAGE_LIMIT = 10;
const TableCellAction = styled(Table.Cell)`
  display: flex;
  justify-content: space-evenly;
  width: 100% !important;
`;

function MarketMapListView(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [searchFields, setSearchFields] = useState({
    search: '',
    goal_id: null,
  });
  const [marketMaps, setMarketMaps] = useState([]);
  const [loadingMarketMaps, setLoadingMarketMaps] = useState(false);
  const [paging, setPaging] = useState({
    page: 1,
    totalPages: 0,
  });

  const loadList = (page = 1) => {
    const { search, goal_id } = searchFields;
    const params = {
      ...(search && { search }),
      ...(goal_id && { goal_id }),
      page,
      limit: DEFAULT_PAGE_LIMIT,
    };
    setLoadingMarketMaps(true);
    sendRequest({
      node: true,
      noLoad: true,
      method: 'market-maps',
      type: 'GET',
      data: params,
      success: (data) => {
        const { count, objects } = data;
        const totalPages = Math.ceil(count / DEFAULT_PAGE_LIMIT);
        setMarketMaps(objects);
        setLoadingMarketMaps(false);
        setPaging({
          ...paging,
          totalPages,
        });
      },
      error: (err) => {
        setLoadingMarketMaps(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnDelete = (id) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `market-maps/${id}`,
              type: 'DELETE',
              success: (_) => {
                triggerEvent('showSnackbar', [
                  { text: 'Market Map deleted', type: 'success' },
                ]);
                loadList();
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadList();
  }, []);

  return (
    <Container>
      <Header as="h2">Market Maps</Header>
      <Segment>
        <Form>
          <Form.Group>
            <Form.Input
              label="Market Map Name"
              placeholder="Search Market Map by name"
              value={searchFields.search}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  search: value,
                });
              }}
              width={10}
            />
            <Form.Select
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={searchFields.goal_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  goal_id: value,
                });
              }}
              width={5}
            />
            <Form.Button
              positive
              onClick={() => loadList()}
              style={{ marginTop: 25 }}
            >
              Search
            </Form.Button>
          </Form.Group>
        </Form>
      </Segment>
      <Segment loading={loadingMarketMaps}>
        <Table singleLine fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={14}>Market Map Name</Table.HeaderCell>
              <Table.HeaderCell width={2}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!loadingMarketMaps && marketMaps.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="2">No Market Map found</Table.Cell>
              </Table.Row>
            )}
            {!loadingMarketMaps &&
              marketMaps.length > 0 &&
              marketMaps.map((marketMap) => (
                <Table.Row key={marketMap.id}>
                  <Table.Cell width={14}>{marketMap.name}</Table.Cell>
                  <TableCellAction width={2}>
                    <Icon
                      link
                      name="pencil"
                      onClick={() =>
                        props.history.push(`/market-maps/${marketMap.id}`)
                      }
                    />
                    <Icon
                      link
                      name="trash"
                      onClick={() => handleOnDelete(marketMap.id)}
                    />
                  </TableCellAction>
                </Table.Row>
              ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                <Pagination
                  defaultActivePage={1}
                  siblingRange={2}
                  totalPages={paging.totalPages}
                  onPageChange={(e, { activePage }) => loadList(activePage)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Link to={`/market-maps/create`}>
        <div className="createButton">
          <span className="material-icons">add</span>
        </div>
      </Link>
    </Container>
  );
}

export default withRouter(MarketMapListView);
