import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import '../../sass/components/common/DropdownMenu.scss';

class DropdownMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      overlayHidden: true,
      open: false,
      top: 0,
    };
    this.buttonElem = null;
  }

  onOptionClick = (e, option) => {
    e.stopPropagation();
    e.preventDefault();
    if (option.disabled) {
      return;
    }
    if (option.onClick) {
      option.onClick();
    }
    this.setState({open: false});
  }

  renderOverlay = () => {
    return (
      <CSSTransition
        in={this.state.open}
        timeout={350}
        classNames='fade'
        onEnter={() => this.setState({overlayHidden: false})}
        onExited={() => this.setState({overlayHidden: true})}
      >
        <div
          className={classnames({
            'menuOverlay': true,
            'hidden': !this.state.open && this.state.overlayHidden,
          })}
          onClick={() => this.setState({open: false})}
        />
      </CSSTransition>
    )
  }

  renderMenu = () => {
    const windowCenter = this.props.windowCenter;
    return (
      <div
        className={classnames({
          'menu': true,
          'hidden': !this.state.open,
          'right': this.props.anchorRight,
          'expandTop': this.props.expandTop,
          'windowCenter': windowCenter,
        })}
        style={{top: windowCenter ? null : this.state.top}}
      >
        {this.props.headerElement ?
          <div className='menuHeader'>{this.props.headerElement}</div>
        : null}
        {this.props.options.map(option => {
          if (option && option.title) {
            return (
              <div
                key={option.title}
                className={classnames({
                  'menuItem': true,
                  'danger': option.danger,
                  'disabled': option.disabled,
                })}
                onClick={e => this.onOptionClick(e, option)}
              >
                <div className='menuItemTitle'>{option.title}</div>
                {option.description ?
                  <div
                    className='menuItemDescription'
                    dangerouslySetInnerHTML={{__html:option.description}}
                  />
                : null}
              </div>
            )
          } else {
            return null; 
          }
        })}
      </div>
    )
  }

  render = () => {
    return (
      <div className='dropdownMenu'>
        <div
          className='buttonElement'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({
              open: !this.state.open,
              top: this.buttonElem.getBoundingClientRect().y + 10,
            })
          }}
          ref={elem => this.buttonElem = elem}
        />
        {this.renderOverlay()}
        {this.renderMenu()}
      </div>
    )
  }

}

export default DropdownMenu;
