import Auth from '@aws-amplify/auth';

/*
 * Amplify.Auth sign up
 * @param props.username
 * @param props.password
 * @param props.attributes
 * @return signUp response or null
 */
export const signUp = async (props) => {
  try {
    const response = await Auth.signUp(props);
    return response;
  } catch (error) {
    console.log('Amplify Auth.signUp error', error);
    return null;
  }
};

/*
 * Amplify.Auth sign in
 * @param email
 * @param password
 * @return signIn response or null
 */
export const signIn = async (email, password) => {
  try {
    const response = await Auth.signIn(email, password);
    return response;
  } catch (error) {
    console.log('Amplify Auth.signIn error', error);
    return null;
  }
};

/*
 * Amplify.Auth sign out
 * @return true or false
 */
export const signOut = async () => {
  try {
    await Auth.signOut();
    return true;
  } catch (error) {
    console.log('Amplify Auth.signOut error', error);
    return false;
  }
};

/*
 * Amplify.Auth forgotPassword
 * @param email
 * @return forgotPassword response or null
 */
export const forgotPassword = async (email) => {
  try {
    const response = await Auth.forgotPassword(email);
    return response;
  } catch (error) {
    console.log('Amplify Auth.forgotPassword error', error);
    return null;
  }
};

/*
 * Amplify.Auth forgotPasswordSubmit
 * @param email
 * @param code
 * @param password
 * @return forgotPasswordSubmit response or null
 */
export const forgotPasswordSubmit = async (email, code, password) => {
  try {
    const response = await Auth.forgotPasswordSubmit(email, code, password);
    return response;
  } catch (error) {
    console.log('Amplify Auth.forgotPasswordSubmit error', error);
    return null;
  }
};

/*
 * Amplify.Auth get jwt token
 * @return jwt token or null
 */
export const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (error) {
    return null;
  }
};

/*
 * Amplify.Auth changePassword
 * @param oldPassword
 * @param newPassword
 * @return changePassword response or null
 */
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.changePassword(user, oldPassword, newPassword);
    return response;
  } catch (error) {
    console.log('Amplify Auth.changePassword error', error);
    return null;
  }
};
