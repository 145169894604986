import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import '../../sass/components/input/LogsList.scss';

class LogsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: {},
    };
  }

  componentDidMount = () => {
  }

  renderField = (field, index) => {
    const from = field.from === undefined || field.from === null ? '-' : `${field.from}`;
    const to = field.to === undefined || field.to === null ? '-' : `${field.to}`;
    return (
      <div key={index} className='fieldItem'>
        <div className='fieldName'>{field.field}</div>
        <div className='fieldFrom'>{from}</div>
        <div className='fieldTo'>{to}</div>
      </div>
    )
  }

  renderUserLink = (user) => {
    const pageLink = isNaN(user.id) ? null : `/users/${user.id}`;
    return (
      <div
        className={classnames({
          'logUser': true,
          'clickable': pageLink
        })}
        onClick={() => pageLink ? this.props.history.push(pageLink) : null}
      >{user.email}</div>
    )
  }

  renderLogItem = (item, index) => {
    const user = item.user || {};
    const expanded = this.state.expanded;
    
    return (
      <div key={index} className='logItem'>
        <div className='logInfo'>
          <div className='logType'>{item.changes ? 'Update' : 'View'}</div>
          <div className='logTime'>
            {moment.unix(item.timestamp).format('HH:mm DD.MM.YYYY')}
          </div>
          {this.renderUserLink(user)}
          <div
            className='expandButton'
            onClick={() => this.setState({
              expanded: {
                ...expanded,
                [index]: !expanded[index]
              }
            })}
          >{expanded[index] ? 'Hide' : 'Show'}</div>
        </div>
        {expanded[index] ?
          item.changes ?
            <div className='fieldsList'>
              <div className='listHeader'>
                <div className='headerName'>Field</div>
                <div className='headerChange'>Changed From</div>
                <div className='headerChange'>Changed To</div>
              </div>
              {(item.changes || []).map(this.renderField)}
            </div>
          : item.view ?
            <div className='fieldView'>{item.view}</div>
          : null
        : null}
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='logsList'>
        {object.map(this.renderLogItem)}
      </div>
    )
  }
}

export default withRouter(LogsList);
