import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import { triggerEvent } from '../../../helpers/global';
import { loadOptions } from '../../../helpers/market_map';

function MarketMapForm({
  formValues = null,
  onSubmitHandler,
  renderRemoteSubmit = null,
  isSingleSDG = false,
}) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    ...(isSingleSDG && { goal_id: null }),
    ...(!isSingleSDG && { goal_ids: [] }),
  });
  const singleValidationSchema = Yup.object({
    name: Yup.string()
      .max(255, 'Must be 255 characters or less')
      .required('Name is required'),
    goal_id: Yup.number()
      .required('SDG is required')
      .typeError('SDG is required'),
  });
  const multipleValidationSchema = Yup.object({
    name: Yup.string()
      .max(255, 'Must be 255 characters or less')
      .required('Name is required'),
    goal_ids: Yup.array().min(1, 'SDG is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: isSingleSDG
      ? singleValidationSchema
      : multipleValidationSchema,
    onSubmit: (values) => onSubmitHandler(values),
  });

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
  }, []);

  useEffect(() => {
    if (formValues) {
      setInitialValues(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    const { isValid, isSubmitting } = formik;
    if (!isValid && isSubmitting) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
    }
  }, [formik.isValid, formik.isSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            name="name"
            label="Market Map Name"
            placeholder="Enter Market Map Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
            {...(formik.touched.name &&
              formik.errors.name && { error: formik.errors.name })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          {isSingleSDG && (
            <Field
              component={Form.Select}
              name="goal_id"
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={formik.values.goal_id}
              onChange={(e, { value }) => {
                formik.setFieldValue('goal_id', value);
              }}
              selectOnBlur={false}
              {...(formik.touched.goal_id &&
                formik.errors.goal_id && { error: formik.errors.goal_id })}
            />
          )}
          {!isSingleSDG && (
            <Field
              component={Form.Select}
              name="goal_ids"
              label="SDGs"
              placeholder="Select one or more SDGs"
              options={optionsGoal}
              clearable
              search
              multiple
              value={formik.values.goal_ids}
              onChange={(e, { value }) => {
                formik.setFieldValue('goal_ids', value);
              }}
              selectOnBlur={false}
              {...(formik.touched.goal_ids &&
                formik.errors.goal_ids && { error: formik.errors.goal_ids })}
            />
          )}
        </Form.Group>
        {!renderRemoteSubmit && (
          <Form.Button positive type="submit">
            Save
          </Form.Button>
        )}
        {renderRemoteSubmit && renderRemoteSubmit()}
      </Form>
    </FormikProvider>
  );
}

export default MarketMapForm;
