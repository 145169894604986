import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/TextInput.scss';

export default class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.input = null;
  }

  handleChange = (e) => {
    let value = e.target.value;
    const properties = this.props.properties;
    if (properties.type === 'integer') {
      value = value.replace(/\D/g, '');
    }
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, value);
    }
  }

  componentDidMount = () => {
  }

  renderTextInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    const type = properties.type === 'integer' ? 'text' : properties.type;
    return (
      <input
        type={type}
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        className="form-control"
        placeholder={this.props.placeholder || properties.placeholder}
        autoComplete={this.props.objectKey}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  renderTextareaInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <textarea
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        className="form-control"
        placeholder={this.props.placeholder || properties.placeholder}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  render = () => {
    const properties = this.props.properties;
    return (
      <div
        className={classnames({
          'textInput': true,
          'simplified': properties.simplified,
        })}
      >
        {['text', 'number', 'integer', 'password', 'amount'].indexOf(properties.type) > -1 ? this.renderTextInput() : null}
        {properties.type === 'textarea' ? this.renderTextareaInput() : null}
      </div>
    )
  }
}
