import React from 'react';

import moment from 'moment';

import '../sass/components/StatisticsView.scss';

export default class StatisticsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  formatAmount = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
  };

  render = () => {
    const object = this.props.object;
    const raisedAmount = object.raised_amount_in_cents / 100;
    const raisedRatio = object.raised_ratio * 100;

    return (
      <div className='statisticsView'>
        <div className='progress green'>
          <div className='bar' style={{ width: `${raisedRatio}%` }}>
            <div className='progress' />
          </div>
        </div>
        <div className='statisticsContainer'>
          <div className='statisticsItem'>
            <div className='value'>
              {object.investments_count}
            </div>
            <div className='label'>
              Investments
            </div>
          </div>

          <div className='statisticsItem'>
            <div className='value'>
              {object.raised_amount_in_cents === '0.0' ?
                'Just Started' : this.formatAmount(raisedAmount)
              }
            </div>
            <div className='label'>
              Raised of {this.formatAmount(object.target_amount)}
            </div>
          </div>

          {object.financing_option === 'equity' ?
            <div className='statisticsItem'>
              <div className='value'>
                {parseFloat(object.equity.toFixed(2))}%
              </div>
              <div className='label'>
                Equity offer
              </div>
            </div>
          : null}

          {object.terms_link ?
            <div className='statisticsItem'>
              <div className='value test-terms_link'>
                <a
                  href={object.terms_link}
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  Terms
                  <i className="material-icons">link</i>
                </a>
              </div>
              <div className='label test-term'>
                Investment Document
              </div>
            </div>
          : null}

          <div className='statisticsItem'>
            <div className='value'>
              {moment.unix(object.expired_at_timestamp).format('DD.MM.YYYY')}
            </div>
            <div className='label'>
              End date
            </div>
          </div>
        </div>
      </div>
    );
  };

}
