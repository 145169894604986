import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Segment,
  Form,
  Icon,
  Table,
  Pagination,
  Header,
  Label,
  Modal,
  Button,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { omit } from 'ramda';

import { sendRequest, triggerEvent } from '../../helpers/global';
import {
  loadOptions,
  modelSeparator,
  othersModelSeparator,
} from '../../helpers/market_map';
import CompanyForm, { RemoteSubmit } from './shared/CompanyForm';

const DEFAULT_PAGE_LIMIT = 10;
const CapitalizeLabel = styled(Label)`
  text-transform: capitalize !important;
  cursor: pointer;
`;

function MarketMapCompanyRequestList(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsValueChain, setOptionsValueChain] = useState([]);
  const [optionsValueChainType, setOptionsValueChainType] = useState([]);
  const optionsStatus = [
    {
      text: 'Pending',
      value: 'pending',
    },
    {
      text: 'Approved',
      value: 'approved',
    },
    {
      text: 'Rejected',
      value: 'rejected',
    },
  ];
  const [searchFields, setSearchFields] = useState({
    search: '',
    status: 'pending',
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
    value_chain_type_id: null,
  });
  const [companyRequests, setCompanyRequests] = useState([]);
  const [loadingCompanyRequests, setLoadingCompanyRequests] = useState(false);
  const [paging, setPaging] = useState({
    page: 1,
    totalPages: 0,
  });
  const [openCompanyFormModal, setOpenCompanyFormModal] = useState(false);
  const [selectedCompanyRequest, setSelectedCompanyRequest] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const loadList = (page = 1) => {
    const {
      search,
      status,
      market_map_id,
      value_chain_id,
      value_chain_type_id,
    } = searchFields;
    const params = {
      ...(search && { search }),
      ...(status && { status }),
      ...(market_map_id && { market_map_id }),
      ...(value_chain_id && { value_chain_id }),
      ...(value_chain_type_id && { value_chain_type_id }),
      page,
      limit: DEFAULT_PAGE_LIMIT,
    };
    setLoadingCompanyRequests(true);
    sendRequest({
      node: true,
      noLoad: true,
      method: 'company-requests',
      type: 'GET',
      data: params,
      success: (data) => {
        const { count, objects } = data;
        const totalPages = Math.ceil(count / DEFAULT_PAGE_LIMIT);
        setCompanyRequests(objects);
        setLoadingCompanyRequests(false);
        setPaging({
          ...paging,
          totalPages,
        });
      },
      error: (err) => {
        setLoadingCompanyRequests(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnDelete = (id) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this Company Request?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `company-requests/${id}`,
              type: 'DELETE',
              success: (data) => {
                triggerEvent('showSnackbar', [
                  { text: 'Company deleted', type: 'success' },
                ]);
                loadList(paging.page);
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  const handleCloseModal = () => {
    setOpenCompanyFormModal(false);
    setSelectedCompanyRequest(null);
    setIsSubmit(false);
  };

  const handleSelectCompanyRequest = (request) => {
    const {
      id,
      name,
      url,
      location,
      company_type_id,
      goal_id,
      market_map_id,
      value_chain_id,
      value_chain_type_id,
      description,
      funding_round,
      funding_amount,
      market_models,
      business_models,
      logo: _logo,
      status,
    } = request;

    let other_market_model = '';
    let other_business_model = '';
    if (!!market_models && market_models.includes('others')) {
      const marketModelsArr = market_models.split(modelSeparator);
      const othersString = marketModelsArr.find((value) =>
        value.includes('others')
      );
      const othersArr = othersString.split(othersModelSeparator);
      other_market_model = othersArr[1];
    }
    if (!!business_models && business_models.includes('others')) {
      const businessModelsArr = business_models.split(modelSeparator);
      const othersString = businessModelsArr.find((value) =>
        value.includes('others')
      );
      const othersArr = othersString.split(othersModelSeparator);
      other_business_model = othersArr[1];
    }

    let logo = '';
    if (_logo) {
      const s3Data = JSON.parse(_logo);
      logo = s3Data.Location;
    }

    setSelectedCompanyRequest({
      id,
      name,
      url: url ?? '',
      location: location ?? '',
      company_type_id: company_type_id ? +company_type_id : null,
      goal_id: goal_id ? +goal_id : null,
      market_map_id,
      value_chain_id: value_chain_id ? +value_chain_id : null,
      value_chain_type_id: value_chain_type_id ? +value_chain_type_id : null,
      description: description ?? '',
      funding_round: funding_round ?? '',
      funding_amount: funding_amount ?? '',
      market_models: !market_models
        ? []
        : market_models
            .split(modelSeparator)
            .map((value) => (value.includes('others') ? 'others' : value)),
      business_models: !business_models
        ? []
        : business_models
            .split(modelSeparator)
            .map((value) => (value.includes('others') ? 'others' : value)),
      other_market_model,
      other_business_model,
      logo_base64: '',
      logo_filename: '',
      logo,
      status,
    });
    setIsSubmit(false);
  };

  const handleRemoteSubmitForm = () => {
    setIsSubmit(true);
  };

  const handleSubmitCompanyRequest = (values) => {
    setIsSubmit(false);
    const market_models = values.market_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_market_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    const business_models = values.business_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_business_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    sendRequest({
      node: true,
      method: `company-requests/${values.id}`,
      type: 'PUT',
      data: {
        ...omit(
          [
            'id',
            'goal_id',
            'other_market_model',
            'other_business_model',
            'value_chain_id',
            'company_type_name',
            'goal_name',
            'market_map_name',
            'value_chain_name',
            'value_chain_type_name',
            'status',
          ],
          values
        ),
        market_models,
        business_models,
      },
      success: (_) => {
        triggerEvent('showSnackbar', [
          { text: 'Company request saved', type: 'success' },
        ]);
        loadList(paging.page);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleApproveRejectCompanyRequest = (id, type) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to ${
          type === 'approve' ? 'Approve' : 'Reject'
        } this Company Request?`,
        confirmText: type === 'approve' ? 'Approve' : 'Reject',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `company-requests/${id}/${type}`,
              type: 'POST',
              success: (_) => {
                triggerEvent('showSnackbar', [
                  {
                    text: `Company request ${
                      type === 'approve' ? 'approved' : 'rejected'
                    }`,
                    type: 'success',
                  },
                ]);
                loadList(paging.page);
                handleCloseModal();
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadOptions('value-chains', setOptionsValueChain);
    loadOptions('value-chain-types', setOptionsValueChainType);
    loadList();
  }, []);

  useEffect(() => {
    if (selectedCompanyRequest) {
      setOpenCompanyFormModal(true);
    }
  }, [selectedCompanyRequest]);

  return (
    <Container>
      <Header as="h2">Company Requests</Header>
      <Segment>
        <Form>
          <Form.Group>
            <Form.Input
              label="Company Name"
              placeholder="Search request by Company Name"
              value={searchFields.search}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  search: value,
                });
              }}
              width={12}
            />
            <Form.Select
              label="Status"
              placeholder="Select Status"
              options={optionsStatus}
              clearable
              search
              value={searchFields.status}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  status: value,
                });
              }}
              width={4}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Select
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={searchFields.goal_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  goal_id: value,
                  market_map_id: null,
                  value_chain_type_id: null,
                });
                loadOptions('market-maps', { goal_id: value });
                loadOptions('value-chain-types', {
                  goal_id: value,
                });
              }}
            />
            <Form.Select
              label="Market Map"
              placeholder="Select Market Map"
              options={optionsMarketMap}
              clearable
              search
              value={searchFields.market_map_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  market_map_id: value,
                  value_chain_type_id: null,
                });
                loadOptions('value-chain-types', {
                  goal_id: searchFields.goal_id,
                  market_map_id: value,
                });
              }}
            />
            <Form.Select
              label="Value Chain Category"
              placeholder="Select Value Chain Category"
              options={optionsValueChainType}
              clearable
              search
              value={searchFields.value_chain_type_id}
              onChange={(e, { value }) =>
                setSearchFields({ ...searchFields, value_chain_type_id: value })
              }
            />
            <Form.Select
              label="Chain Position"
              placeholder="Select Chain Position"
              options={optionsValueChain}
              clearable
              search
              value={searchFields.value_chain_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  value_chain_id: value,
                  value_chain_type_id: null,
                });
                loadOptions('value-chain-types', {
                  goal_id: searchFields.goal_id,
                  market_map_id: searchFields.market_map_id,
                  value_chain_id: value,
                });
              }}
            />
          </Form.Group>
          <Form.Button positive onClick={() => loadList()}>
            Search
          </Form.Button>
        </Form>
      </Segment>
      <Segment loading={loadingCompanyRequests}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>SDG</Table.HeaderCell>
              <Table.HeaderCell>Market Map Name</Table.HeaderCell>
              <Table.HeaderCell>Value Chain Category</Table.HeaderCell>
              <Table.HeaderCell>Chain Position</Table.HeaderCell>
              <Table.HeaderCell>Company Name</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!loadingCompanyRequests && companyRequests.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="7">No Company Requests found</Table.Cell>
              </Table.Row>
            )}
            {!loadingCompanyRequests &&
              companyRequests.length > 0 &&
              companyRequests.map((request) => (
                <Table.Row key={request.id}>
                  <Table.Cell>
                    {request.goal_id && request.goal_name && (
                      <Link to={`/categories/${request.goal_id}`}>
                        {request.goal_name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {request.market_map && (
                      <Link to={`/market-maps/${request.market_map.id}`}>
                        {request.market_map.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {request.value_chain_type && (
                      <Link
                        to={`/market-map-categories/${request.value_chain_type.id}`}
                      >
                        {request.value_chain_type.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>{request.value_chain_name}</Table.Cell>
                  <Table.Cell>{request.name}</Table.Cell>
                  <Table.Cell>
                    <CapitalizeLabel
                      color={
                        request.status === 'pending'
                          ? 'yellow'
                          : request.status === 'approved'
                          ? 'green'
                          : 'red'
                      }
                      onClick={() => handleSelectCompanyRequest(request)}
                    >
                      {request.status}
                    </CapitalizeLabel>
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Icon
                      link
                      name="pencil"
                      onClick={() => handleSelectCompanyRequest(request)}
                    />
                    <Icon
                      link
                      name="trash"
                      onClick={() => handleOnDelete(request.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="7">
                <Pagination
                  defaultActivePage={1}
                  siblingRange={2}
                  totalPages={paging.totalPages}
                  onPageChange={(e, { activePage }) => loadList(activePage)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Modal
        onClose={() => handleCloseModal()}
        onOpen={() => setOpenCompanyFormModal(true)}
        open={openCompanyFormModal}
        size="large"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header size="medium">Company Request</Header>
        <Modal.Content scrolling>
          {selectedCompanyRequest && (
            <CompanyForm
              formValues={selectedCompanyRequest}
              onSubmitHandler={handleSubmitCompanyRequest}
              renderRemoteSubmit={() => (
                <RemoteSubmit submit={isSubmit} setSubmit={setIsSubmit} />
              )}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Close</Button>
          {selectedCompanyRequest &&
            selectedCompanyRequest.status === 'pending' && (
              <>
                <Button positive onClick={() => handleRemoteSubmitForm()}>
                  Save
                </Button>
                <Button.Group style={{ float: 'left' }}>
                  <Button
                    positive
                    icon
                    labelPosition="left"
                    onClick={() =>
                      handleApproveRejectCompanyRequest(
                        selectedCompanyRequest.id,
                        'approve'
                      )
                    }
                  >
                    <Icon name="check" />
                    Approve
                  </Button>
                  <Button.Or />
                  <Button
                    negative
                    icon
                    labelPosition="right"
                    onClick={() =>
                      handleApproveRejectCompanyRequest(
                        selectedCompanyRequest.id,
                        'reject'
                      )
                    }
                  >
                    <Icon name="close" />
                    Reject
                  </Button>
                </Button.Group>
              </>
            )}
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default withRouter(MarketMapCompanyRequestList);
