import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/FramePopup.scss';

class FramePopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      title: null,
      src: null,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('openFramePopup', this.openFramePopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('openFramePopup', this.openFramePopup);
    }
  }

  openFramePopup = (e) => {
    const { title, src } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      title,
      src,
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.onClose();
    }
  }

  onClose = () => {
    if (this.props.global) {
      this.setState({show: false});
    } else if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render = () => {
    const { show, title, src } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'framePopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='poupTitle'>{title}</div>
              <div className='closeButton' onClick={this.onClose}/>
              <iframe title={title} src={src}/>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default FramePopup;
