import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { omit } from 'ramda';

import { triggerEvent } from '../../../helpers/global';
import ImageUpload from './ImageUpload';

const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

function MarketMapExpertForm({
  formValues = null,
  onSubmitHandler,
  renderRemoteSubmit = null,
}) {
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [initialValues, setInitialValues] = useState({
    name: '',
    position: '',
    linkedin_url: '',
    profile_picture_base64: '',
    profile_picture_filename: '',
    profile_picture_url: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Name is required'),
      position: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Name is required'),
      linkedin_url: Yup.string().matches(urlRegex, 'Invalid URL'),
    }),
    onSubmit: (values) => onSubmitHandler(values),
  });

  const handleImageUploadSetter = ({
    base64String,
    filename,
    imageUrlUpload,
  }) => {
    formik.setFieldValue('profile_picture_base64', base64String);
    formik.setFieldValue('profile_picture_filename', filename);
    formik.setFieldValue('profile_picture_url', imageUrlUpload);
  };

  useEffect(() => {
    if (formValues) {
      setInitialValues({
        ...omit(['profile_picture'], formValues),
      });
      if (formValues.profile_picture) {
        setProfilePictureUrl(formValues.profile_picture);
      }
    }
  }, [formValues]);

  useEffect(() => {
    const { isValid, isSubmitting } = formik;
    if (!isValid && isSubmitting) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
    }
  }, [formik.isValid, formik.isSubmitting]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              placeholder="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              autoComplete="off"
              {...(formik.touched.name &&
                formik.errors.name && { error: formik.errors.name })}
            />
            <Form.Input
              name="position"
              label="Position"
              placeholder="Enter Position"
              value={formik.values.position}
              onChange={formik.handleChange}
              autoComplete="off"
              {...(formik.touched.position &&
                formik.errors.position && { error: formik.errors.position })}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="linkedin_url"
              label="LinkedIn URL"
              placeholder="Enter LinkedIn URL"
              value={formik.values.linkedin_url}
              onChange={formik.handleChange}
              autoComplete="off"
              {...(formik.touched.linkedin_url &&
                formik.errors.linkedin_url && {
                  error: formik.errors.linkedin_url,
                })}
            />
          </Form.Group>
          <label style={{ fontWeight: 'bold' }}>Profile picture</label>
          <ImageUpload
            imageUrl={profilePictureUrl || formik.values.profile_picture_base64}
            handlerImageAccepted={handleImageUploadSetter}
            toUploadImageUrl={formik.values.profile_picture_url}
          />
          {!renderRemoteSubmit && (
            <Form.Button positive type="submit">
              Save
            </Form.Button>
          )}
          {renderRemoteSubmit && renderRemoteSubmit()}
        </Form>
      </FormikProvider>
    </>
  );
}

export default MarketMapExpertForm;
