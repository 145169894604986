export default {
  section: "Data",
  config: {
    method: "solutions/",
    title: "Solutions",
    objectName: "Solution",
    disableAdd: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    category_object: {
      type: "page-link",
      page: "/categories/:id",
      title: "Category",
      labelKey: "name",
      width: "auto",
      showCondition: (data) => data.id,
    },
    subcategory_object: {
      type: "page-link",
      page: "/subcategories/:id",
      title: "Subcategory",
      labelKey: "name",
      width: "auto",
      showCondition: (data) => !data.id,
    },
    subcategory_id: {
      type: "select",
      title: "Subcategory",
      request: "common_data/subcategories",
      clearable: true,
    },
    description: {
      default: "",
      type: "textarea",
      title: "Description",
    },
    business_profile_object: {
      type: "page-link",
      page: "/business_profiles/:id",
      title: "Business Profile",
      labelKey: "name",
      width: "auto",
      showCondition: (data) => data.id,
    }
  }
};
