export default {
  section: "Data",
  config: {
    method: "categories/",
    title: "Goals",
    objectName: "Category",
    disableAdd: false,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    subcategories: {
      type: "links-list",
      title: "Subcategories",
      page: "/subcategories/:id",
    }
  }
};
