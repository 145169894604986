import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

import { triggerEvent, sendRequest } from '../../helpers/global';

import '../../sass/components/input/SelectInput.scss';

class SelectInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: props.properties.options || [],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const properties = this.props.properties;
    if (prevProps.properties.options !== this.props.properties.options) {
      this.setState({options: this.props.properties.options});
    } else if (prevProps.object !== this.props.object) {
      if (properties.async && !prevProps.object) {
        this.loadInitialOptions();
      }
    }
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  componentDidMount = () => {
    this.loadInitialOptions();
  }

  loadInitialOptions = () => {
    const properties = this.props.properties;
    const isMulti = properties.type === 'multi-select';
    if (properties.request) {
      if (properties.async && !this.props.object) {
        return;
      }
      triggerEvent('addLoad');
      sendRequest({
        type: 'GET',
        node: !!properties.node,
        method: properties.request,
        data: properties.async ? {
          ids: isMulti ? this.props.object : [this.props.object],
        } : null,
        success: (data) => {
          triggerEvent('removeLoad');
          if (properties.object_data) {
            const id = properties.option_id_key || 'id';
            const name = properties.option_id_name || 'name';
            const options = data.objects.map((obj) => ({
              id: obj[id],
              name: obj[name],
            }));
            this.setState({options});
          } else {
            this.setState({options: data});
          }
        },
        error: (error) => {
          triggerEvent('removeLoad');
        }
      });
    }
  }

  loadOptions = (inputValue, callback) => {
    const properties = this.props.properties;
    const searchKey = properties.searchKey || 'name';
    if (properties.request) {
      sendRequest({
        type: 'GET',
        node: !!properties.node,
        method: properties.request,
        data: {
          name: inputValue
        },
        success: (data) => {
          let optionsCache = {};
          this.state.options.forEach(i => {
            optionsCache[i.id] = i[searchKey].trim();
          });
          data.forEach(i => {
            optionsCache[i.id] = i[searchKey].trim()
          });
          const options = Object.keys(optionsCache).map(key => ({
            id: key, [searchKey]: optionsCache[key]
          }));
          this.setState({options});
          callback(
            options
              .map(i => ({ value: i.id, label: i[searchKey] }))
              .filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
          );
        },
        error: (error) => {
          callback([]);
        }
      });
    } else {
      callback([]);
    }
  };

  render = () => {
    const object = this.props.object;
    const properties = this.props.properties;
    const isMulti = properties.type === 'multi-select';
    const options = this.state.options.map(i => ({
      value: i.id,
      label: i[properties.searchKey || 'name']
    }));
    const value = isMulti
      ? (object || []).map(v => options.find(i => i.value == v)).filter(v => !!v) // eslint-disable-line eqeqeq
      : options.find(i => i.value == object); // eslint-disable-line eqeqeq
    const selectProps = {
      value: value || null,
      onChange: e => {
        if (isMulti) {
          this.handleChange(e ? e.map(i => i.value) : []);
        } else {
          this.handleChange(e ? e.value : null);
        }
      },
      onFocus: () => this.setState({focus: true}),
      onBlur: () => this.setState({focus: false}),
      isMulti: isMulti,
      isDisabled: this.props.disabled,
      className: 'reactSelect',
      classNamePrefix: 'reactSelect',
      isClearable: properties.clearable,
      placeholder: properties.placeholder || 'Select...',
      noOptionsMessage: inputValue => properties.async ? 'Search...' : 'No options',
    }
    return (
      <div
        className={classnames({
          'selectInput': true,
          'readOnly': this.props.disabled,
        })}
      >
        {properties.async
          ? <AsyncSelect
            cacheOptions={!isMulti}
            defaultOptions={options}
            loadOptions={this.loadOptions}
            {...selectProps}
          />
          : <Select
            options={options}
            {...selectProps}
          />
        }
      </div>
    )
  }
}

export default SelectInput;
