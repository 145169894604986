export default {
  section: "Data",
  config: {
    method: "business_profiles/",
    title: "Business profiles",
    objectName: "business_profiles",
    search: {
      name: {
        type: "text",
        title: "Name",
      },
      country_id: {
        type: "select",
        title: "Location",
        request: "common_data/countries",
        clearable: true,
      },
      profile_type: {
        type: "select",
        title: "Profile Type",
        clearable: true,
        method: "business_profiles/:id",
        width: "auto",
        options:  [
          {
            id: '',
            key: '',
            name: 'Select Profile Type',
          },
          {
            id: 'Investor',
            key: 'Investor',
            name: 'Investor',
          },
          {
            id: 'Startup',
            key: 'Startup',
            name: 'Startup',
          },
        
          {
            id: 'Corporate',
            key: 'Corporate',
            name: 'Corporate',
          },
          {
            id: 'NGO',
            key: 'NGO',
            name: 'NGO',
          },
          {
            id: 'Academia',
            key: 'Academia',
            name: 'Academia',
          },
          {
            id: 'Government',
            key: 'Government',
            name: 'Government',
          },
          {
            id: 'Accelerator',
            key: 'Accelerator',
            name: 'Accelerator',
          },
          {
            id: 'Consultant',
            key: 'Consultant',
            name: 'Consultant',
          },
          {
            id: 'Others',
            key: 'Others',
            name: 'Others',
          }
        ]
      },
    },
    disableAdd: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    image: {
      type: "file",
      external: true,
      title: "Image",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    user_id: {
      type: "select",
      title: "Owner",
      clearable: true,
      request: "common_data/users",
    },
    description: {
      default: "",
      type: "textarea",
      title: "Description",
    },
    user_object: {
      default: [],
      type: "page-link",
      title: "Owner",
      page: "/users/:id",
      width: "auto",
      labelKey: "name",
      showCondition: object => object?.user_object,
    },
    manager_ids: {
      type: "multi-select",
      title: "Managers",
      clearable: true,
      request: "common_data/users",
      // request: "users/manager",
      async: true,
    },
    profile_status: {
      default: "",
      type: "select",
      title: "Profile Status",
      // preset: true,
      options: [
        { id: true, name: 'Active profile' },
        { id: false, name: 'Hidden profile' }
      ],
    },
    website: {
      default: "",
      type: "text",
      title: "Website",
    },
    city: {
      default: "",
      type: "text",
      title: "City",
    },
    youtube_url: {
      default: "",
      type: "text",
      title: "Youtube URL",
    },
    facebook_url: {
      default: "",
      type: "text",
      title: "Facebook URL",
    },
    instagram_url: {
      default: "",
      type: "text",
      title: "Instagram URL",
    },
    twitter_url: {
      default: "",
      type: "text",
      title: "Twitter URL",
    },
    linkedin_url: {
      default: "",
      type: "text",
      title: "Linkedin URL",
    },
    chats: {
      default: [],
      type: "user-chats",
      title: "Chats",
      side: "business_profile",
      showCondition: object => !!(object.chats || []).length,
    },
    profile_type: {
      type: "select",
      title: "Profile Type",
      clearable: true,
      method: "business_profiles/:id",
      width: "auto",
      options:  [
        {
          id: '',
          key: '',
          name: 'Select Profile Type',
        },
        {
          id: 'Investor',
          key: 'Investor',
          name: 'Investor',
        },
        {
          id: 'Startup',
          key: 'Startup',
          name: 'Startup',
        },
      
        {
          id: 'Corporate',
          key: 'Corporate',
          name: 'Corporate',
        },
        {
          id: 'NGO',
          key: 'NGO',
          name: 'NGO',
        },
        {
          id: 'Academia',
          key: 'Academia',
          name: 'Academia',
        },
        {
          id: 'Government',
          key: 'Government',
          name: 'Government',
        },
        {
          id: 'Accelerator',
          key: 'Accelerator',
          name: 'Accelerator',
        },
        {
          id: 'Consultant',
          key: 'Consultant',
          name: 'Consultant',
        },
        {
          id: 'Others',
          key: 'Others',
          name: 'Others',
        }
      ]
    },
    country_id: {
      type: "select",
      title: "Location",
      request: "common_data/countries",
      clearable: true,
    },
    goal_ids: {
      type: "multi-select",
      title: "Goals",
      request: "common_data/goals",
    },
    objective_ids: {
      type: "multi-select",
      title: "Objectives",
      searchKey: "title",
      request: "common_data/objectives",
    },
    solutions: {
      type: "links-list",
      title: "Solutions",
      page: "/solutions/:id",
      showCondition: object => object.solutions?.length,
    },
  }
};
