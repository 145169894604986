import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Form,
  Icon,
  Modal,
  Button,
  Tab,
  Pagination,
  Segment,
} from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Papa from 'papaparse';
import { omit } from 'ramda';

import {
  csvColumns,
  loadOptions,
  formatCsvParsedDataToFormData,
  modelSeparator,
  othersModelSeparator,
} from '../../../helpers/market_map';
import { downloadBlob } from '../../../helpers/file_helper';
import { sendRequest, triggerEvent } from '../../../helpers/global';
import Storage from '../../../helpers/Storage';
import CompanyForm from './CompanyForm';

const DropzoneContainer = styled.div`
  border: 1px dashed #aaa;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-tracks: center;
  align-items: center;
  cursor: pointer;
`;

function BulkUploadModal(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsValueChain, setOptionsValueChain] = useState([]);
  const [optionsValueChainType, setOptionsValueChainType] = useState([]);
  const [optionsCompanyType, setOptionsCompanyType] = useState([]);
  const [initialOptions, setInitialOptions] = useState({
    goals: null,
    market_maps: null,
    value_chains: null,
    value_chain_types: null,
    company_types: null,
  });
  const [fields, setFields] = useState({
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
    value_chain_type_id: null,
    company_type_id: null,
    rows: 10,
  });
  const [isDownloadSubmitted, setIsDownloadSubmitted] = useState(false);
  const [parsedCsvData, setParsedCsvData] = useState(null);
  const [page, setPage] = useState(0);
  const onDrop = useCallback(
    (acceptedFiles) => {
      const rowsCsvData = [];
      if (acceptedFiles.length) {
        Papa.parse(acceptedFiles[0], {
          skipEmptyLines: 'greedy',
          header: true,
          worker: true,
          step: function (row) {
            rowsCsvData.push(row.data);
          },
          complete: function () {
            const formatted = formatCsvParsedDataToFormData(
              rowsCsvData,
              initialOptions
            );
            if (formatted.length) {
              Storage.addUploadCompanies(formatted);
              props.history.push(`/market-map-companies/bulk-upload`);
            }
          },
        });
      }
    },
    [initialOptions]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'text/csv',
    maxFiles: 1,
  });

  const renderTabUpload = () => (
    <Tab.Pane>
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop CSV file here...</p>
        ) : (
          <p>Drag 'n' drop CSV file here, or click to select file</p>
        )}
      </DropzoneContainer>
      {parsedCsvData && (
        <>
          {renderPagination()}
          <Segment>
            <CompanyForm
              formValues={parsedCsvData[page]}
              onSubmitHandler={handleOnSubmit}
            />
          </Segment>
          {renderPagination()}
        </>
      )}
    </Tab.Pane>
  );

  const renderTabDownload = () => (
    <Tab.Pane>
      <Form>
        <Form.Group widths="equal">
          <Form.Select
            label="SDG"
            placeholder="Select SDG"
            options={optionsGoal}
            clearable
            search
            value={fields.goal_id}
            required
            onChange={(e, { value }) => {
              setFields({
                ...fields,
                goal_id: value,
                market_map_id: null,
                value_chain_type_id: null,
              });
              loadOptions('market-maps', setOptionsMarketMap, {
                goal_id: value,
              });
              loadOptions('value-chain-types', setOptionsValueChainType, {
                goal_id: value,
              });
            }}
            {...(isDownloadSubmitted && !fields.goal_id && { error: true })}
          />
          <Form.Select
            label="Market Map"
            placeholder="Select Market Map"
            options={optionsMarketMap}
            clearable
            search
            value={fields.market_map_id}
            required
            onChange={(e, { value }) => {
              setFields({
                ...fields,
                market_map_id: value,
                value_chain_type_id: null,
              });
              loadOptions('value-chain-types', setOptionsValueChainType, {
                goal_id: fields.goal_id,
                market_map_id: value,
              });
            }}
            {...(isDownloadSubmitted &&
              !fields.market_map_id && { error: true })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            label="Chain Position"
            placeholder="Select Chain Position"
            options={optionsValueChain}
            clearable
            search
            value={fields.value_chain_id}
            required
            onChange={(e, { value }) => {
              setFields({
                ...fields,
                value_chain_id: value,
                value_chain_type_id: null,
              });
              loadOptions('value-chain-types', setOptionsValueChainType, {
                goal_id: fields.goal_id,
                market_map_id: fields.market_map_id,
                value_chain_id: value,
              });
            }}
            {...(isDownloadSubmitted &&
              !fields.value_chain_id && { error: true })}
          />
          <Form.Select
            label="Value Chain Category"
            placeholder="Select Value Chain Category"
            options={optionsValueChainType}
            clearable
            search
            value={fields.value_chain_type_id}
            required
            onChange={(e, { value }) =>
              setFields({ ...fields, value_chain_type_id: value })
            }
            {...(isDownloadSubmitted &&
              !fields.value_chain_type_id && { error: true })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            label="Company Type"
            placeholder="Select Company Type"
            options={optionsCompanyType}
            clearable
            search
            value={fields.company_type_id}
            onChange={(e, { value }) =>
              setFields({ ...fields, company_type_id: value })
            }
          />
          <Form.Input
            name="rows"
            label="Number of rows"
            value={fields.rows}
            onChange={(e, data) => {
              setFields({ ...fields, rows: +data.value });
            }}
            autoComplete="off"
            type="number"
            input={{
              min: 1,
              max: 100,
            }}
          />
        </Form.Group>
        <Form.Button positive onClick={() => downloadTemplate()}>
          <Icon name="download" />
          Download CSV template
        </Form.Button>
      </Form>
    </Tab.Pane>
  );

  const renderPagination = () => (
    <>
      <Pagination
        boundaryRange={0}
        defaultActivePage={1}
        siblingRange={2}
        totalPages={parsedCsvData.length}
        onPageChange={(e, { activePage }) => setPage(activePage)}
      />
    </>
  );

  const downloadTemplate = () => {
    let data = [];
    const {
      goal_id,
      market_map_id,
      value_chain_id,
      value_chain_type_id,
      company_type_id,
      rows,
    } = fields;

    setIsDownloadSubmitted(true);
    if (!goal_id || !market_map_id || !value_chain_id || !value_chain_type_id) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
      return;
    }

    if (
      goal_id ||
      market_map_id ||
      value_chain_id ||
      value_chain_type_id ||
      company_type_id
    ) {
      const goal = optionsGoal.find((option) => +option.value === +goal_id);
      const marketMap = optionsMarketMap.find(
        (option) => +option.value === +market_map_id
      );
      const valueChain = optionsValueChain.find(
        (option) => +option.value === +value_chain_id
      );
      const valueChainType = optionsValueChainType.find(
        (option) => +option.value === +value_chain_type_id
      );
      const companyType = optionsCompanyType.find(
        (option) => +option.value === +company_type_id
      );
      data = Array.from(Array(rows)).map(() => {
        const filledRow = {};
        csvColumns.map((column) => {
          if (column === 'SDG') {
            filledRow[column] = goal ? goal.text : goal_id;
          } else if (column === 'Market Map Name') {
            filledRow[column] = marketMap ? marketMap.text : market_map_id;
          } else if (column === 'Chain Position') {
            filledRow[column] = valueChain ? valueChain.text : value_chain_id;
          } else if (column === 'Value Chain Category') {
            filledRow[column] = valueChainType
              ? valueChainType.text
              : value_chain_type_id;
          } else if (column === 'Company Type') {
            filledRow[column] = companyType
              ? companyType.text
              : company_type_id;
          } else {
            filledRow[column] = '';
          }
          return column;
        });

        return filledRow;
      });
    } else {
      const emptyRow = {};
      csvColumns.map((column) => {
        emptyRow[column] = '';
        return column;
      });
      data.push(emptyRow);
    }

    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    downloadBlob(blob, `template-${new Date().getTime()}.csv`);
  };

  const handleOnSubmit = (values) => {
    const market_models = values.market_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_market_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    const business_models = values.business_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_business_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    sendRequest({
      node: true,
      method: 'companies',
      type: 'POST',
      data: {
        ...omit(
          [
            'goal_id',
            'other_market_model',
            'other_business_model',
            'value_chain_id',
          ],
          values
        ),
        market_models,
        business_models,
      },
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Company saved', type: 'success' },
        ]);
        const slicedParsedCsvData = [
          ...items.slice(0, page),
          ...items.slice(page + 1),
        ];
        setParsedCsvData(slicedParsedCsvData);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadOptions('market-maps', setOptionsMarketMap);
    loadOptions('value-chains', setOptionsValueChain);
    loadOptions('value-chain-types', setOptionsValueChainType);
    loadOptions('company-types', setOptionsCompanyType);
  }, []);

  useEffect(() => {
    const {
      goals,
      market_maps,
      value_chains,
      value_chain_types,
      company_types,
    } = initialOptions;

    if (goals === null && optionsGoal.length) {
      setInitialOptions({
        ...initialOptions,
        goals: optionsGoal,
      });
    }
    if (market_maps === null && optionsMarketMap.length) {
      setInitialOptions({
        ...initialOptions,
        market_maps: optionsMarketMap,
      });
    }
    if (value_chains === null && optionsValueChain.length) {
      setInitialOptions({
        ...initialOptions,
        value_chains: optionsValueChain,
      });
    }
    if (value_chain_types === null && optionsValueChainType.length) {
      setInitialOptions({
        ...initialOptions,
        value_chain_types: optionsValueChainType,
      });
    }
    if (company_types === null && optionsCompanyType.length) {
      setInitialOptions({
        ...initialOptions,
        company_types: optionsCompanyType,
      });
    }
  }, [
    optionsGoal,
    optionsMarketMap,
    optionsValueChain,
    optionsValueChainType,
    optionsCompanyType,
  ]);

  useEffect(() => {
    if (props.open) {
      setIsDownloadSubmitted(false);
    }
  }, [props.open]);

  return (
    <Modal
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      open={props.open}
      size="small"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header size="medium">Bulk Upload Companies</Modal.Header>
      <Modal.Content>
        <Tab
          defaultActiveIndex={0}
          panes={[
            {
              menuItem: 'Upload',
              render: renderTabUpload,
            },
            {
              menuItem: 'Download template',
              render: renderTabDownload,
            },
          ]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.setOpen(false)}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default withRouter(BulkUploadModal);
