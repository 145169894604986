import { createStore, combineReducers } from 'redux';
import localForage from 'localforage';

const dataStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case 'data':
      return Object.assign({}, state, {
        [action.key]: action.value,
      });
    default:
      return state;
  }
};

const setupStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case 'setup':
      return Object.assign({}, state, {
        [action.key]: action.value,
      });
    default:
      return state;
  }
};

const marketMapInitialState = {
  uploadCompanies: [],
};
const marketMapStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case 'add_upload_companies':
      return {
        ...marketMapInitialState,
        uploadCompanies: [...action.value],
      };
    case 'clear_upload_companies':
      return {
        ...marketMapInitialState,
        uploadCompanies: [],
      };
    default:
      return state;
  }
};

const stateReducer = combineReducers({
  data: dataStateReducer,
  setup: setupStateReducer,
  marketMap: marketMapStateReducer,
});

let storeInstance = null;

export default class Storage {
  static getStore() {
    if (!storeInstance) {
      storeInstance = createStore(stateReducer);
    }
    return storeInstance;
  }

  //Data
  static setData(key, data) {
    localForage.setItem(`fundedhere-admin:data:${key}`, data || null);
    this.getStore().dispatch({
      type: 'data',
      key: key,
      value: data,
    });
  }

  static getData(key) {
    return this.getStore().getState()['data'][key];
  }

  static async loadData(key) {
    try {
      const data = await localForage.getItem(`fundedhere-admin:data:${key}`);
      this.getStore().dispatch({
        type: 'data',
        key: key,
        value: data,
      });
      return data;
    } catch (ex) {
      return null;
    }
  }

  // Setup
  static setSetup(key, value) {
    this.getStore().dispatch({ type: 'setup', key, value });
  }
  static getSetup(key) {
    return this.getStore().getState()['setup'][key];
  }

  // Market Map
  static getUploadCompanies() {
    return this.getStore().getState()['marketMap']['uploadCompanies'];
  }
  static addUploadCompanies(value) {
    this.getStore().dispatch({
      type: 'add_upload_companies',
      value,
    });
  }
  static clearUploadCompanies() {
    this.getStore().dispatch({
      type: 'clear_upload_companies',
    });
  }
}
