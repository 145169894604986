import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Segment,
  Form,
  Icon,
  Table,
  Pagination,
  Header,
  Label,
  Modal,
  Button,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { sendRequest, triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';
import MarketMapForm from './shared/MarketMapForm';
import { RemoteSubmit } from './shared/CompanyForm';
import { omit } from 'ramda';

const DEFAULT_PAGE_LIMIT = 10;
const CapitalizeLabel = styled(Label)`
  text-transform: capitalize !important;
  cursor: pointer;
`;
const TableCellAction = styled(Table.Cell)`
  display: flex;
  justify-content: space-evenly;
  width: 100% !important;
`;

function MarketMapRequestList(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const optionsStatus = [
    {
      text: 'Pending',
      value: 'pending',
    },
    {
      text: 'Approved',
      value: 'approved',
    },
    {
      text: 'Rejected',
      value: 'rejected',
    },
  ];
  const [searchFields, setSearchFields] = useState({
    search: '',
    goal_id: null,
    status: 'pending',
  });
  const [marketMapRequest, setMarketMapRequest] = useState([]);
  const [loadingMarketMapRequests, setLoadingMarketMapRequests] =
    useState(false);
  const [paging, setPaging] = useState({
    page: 1,
    totalPages: 0,
  });
  const [openMarketMapFormModal, setOpenMarketMapFormModal] = useState(false);
  const [selectedMarketMapRequest, setSelectedMarketMapRequest] =
    useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const loadList = (page = 1) => {
    const { search, goal_id, status } = searchFields;
    const params = {
      ...(search && { search }),
      ...(goal_id && { goal_id }),
      ...(status && { status }),
      page,
      limit: DEFAULT_PAGE_LIMIT,
    };
    setLoadingMarketMapRequests(true);
    sendRequest({
      node: true,
      noLoad: true,
      method: 'market-map-requests',
      type: 'GET',
      data: params,
      success: (data) => {
        const { count, objects } = data;
        const totalPages = Math.ceil(count / DEFAULT_PAGE_LIMIT);
        setMarketMapRequest(objects);
        setLoadingMarketMapRequests(false);
        setPaging({
          ...paging,
          totalPages,
        });
      },
      error: (err) => {
        setLoadingMarketMapRequests(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOnDelete = (id) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to delete this?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `market-map-requests/${id}`,
              type: 'DELETE',
              success: (_) => {
                triggerEvent('showSnackbar', [
                  { text: 'Market Map Request deleted', type: 'success' },
                ]);
                loadList();
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  const handleCloseModal = () => {
    setOpenMarketMapFormModal(false);
    setSelectedMarketMapRequest(null);
    setIsSubmit(false);
  };

  const handleSelectedMarketMapRequest = (request) => {
    const { id, name, goal_id, status } = request;

    setSelectedMarketMapRequest({
      id,
      name,
      goal_id,
      status,
    });
    setIsSubmit(false);
  };

  const handleRemoteSubmitForm = () => {
    setIsSubmit(true);
  };

  const handleSubmitMarketMapRequest = (values) => {
    setIsSubmit(false);
    sendRequest({
      node: true,
      method: `market-map-requests/${values.id}`,
      type: 'PUT',
      data: omit(['id', 'status'], values),
      success: (_) => {
        triggerEvent('showSnackbar', [
          { text: 'Market Map request saved', type: 'success' },
        ]);
        loadList(paging.page);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleApproveRejectMarketMapRequest = (id, type) => {
    triggerEvent('showConfirmation', [
      {
        title: `Are you sure want to ${
          type === 'approve' ? 'Approve' : 'Reject'
        } this Market Map Request?`,
        confirmText: type === 'approve' ? 'Approve' : 'Reject',
        cancelText: 'Cancel',
        callback: (confirm) => {
          if (confirm) {
            sendRequest({
              node: true,
              method: `market-map-requests/${id}/${type}`,
              type: 'POST',
              success: (_) => {
                triggerEvent('showSnackbar', [
                  {
                    text: `Market Map request ${
                      type === 'approve' ? 'approved' : 'rejected'
                    }`,
                    type: 'success',
                  },
                ]);
                loadList(paging.page);
                handleCloseModal();
              },
              error: (err) => {
                triggerEvent('showSnackbar', [
                  { text: err.message, type: 'error' },
                ]);
              },
            });
          }
        },
      },
    ]);
  };

  useEffect(() => {
    loadOptions('goals', setOptionsGoal);
    loadList();
  }, []);

  useEffect(() => {
    if (selectedMarketMapRequest) {
      setOpenMarketMapFormModal(true);
    }
  }, [selectedMarketMapRequest]);

  return (
    <Container>
      <Header as="h2">Market Map Requests</Header>
      <Segment>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              label="Market Map Name"
              placeholder="Search Market Map by name"
              value={searchFields.search}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  search: value,
                });
              }}
            />
            <Form.Select
              label="SDG"
              placeholder="Select SDG"
              options={optionsGoal}
              clearable
              search
              value={searchFields.goal_id}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  goal_id: value,
                });
              }}
            />
            <Form.Select
              label="Status"
              placeholder="Select Status"
              options={optionsStatus}
              clearable
              search
              value={searchFields.status}
              onChange={(e, { value }) => {
                setSearchFields({
                  ...searchFields,
                  status: value,
                });
              }}
            />
          </Form.Group>
          <Form.Button positive onClick={() => loadList()}>
            Search
          </Form.Button>
        </Form>
      </Segment>
      <Segment loading={loadingMarketMapRequests}>
        <Table singleLine fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={5}>Market Map Name</Table.HeaderCell>
              <Table.HeaderCell width={5}>SDG</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width={2}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!loadingMarketMapRequests && marketMapRequest.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan="4">
                  No Market Map Requests found
                </Table.Cell>
              </Table.Row>
            )}
            {!loadingMarketMapRequests &&
              marketMapRequest.length > 0 &&
              marketMapRequest.map((request) => (
                <Table.Row key={request.id}>
                  <Table.Cell width={5}>{request.name}</Table.Cell>
                  <Table.Cell width={5}>
                    {request.goal_id && request.goal && request.goal.name && (
                      <Link to={`/categories/${request.goal_id}`}>
                        {request.goal.name}
                      </Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <CapitalizeLabel
                      color={
                        request.status === 'pending'
                          ? 'yellow'
                          : request.status === 'approved'
                          ? 'green'
                          : 'red'
                      }
                      onClick={() => handleSelectedMarketMapRequest(request)}
                    >
                      {request.status}
                    </CapitalizeLabel>
                  </Table.Cell>
                  <TableCellAction width={2}>
                    <Icon
                      link
                      name="pencil"
                      onClick={() => handleSelectedMarketMapRequest(request)}
                    />
                    <Icon
                      link
                      name="trash"
                      onClick={() => handleOnDelete(request.id)}
                    />
                  </TableCellAction>
                </Table.Row>
              ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                <Pagination
                  defaultActivePage={1}
                  siblingRange={2}
                  totalPages={paging.totalPages}
                  onPageChange={(e, { activePage }) => loadList(activePage)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
      <Modal
        onClose={() => handleCloseModal()}
        onOpen={() => setOpenMarketMapFormModal(true)}
        open={openMarketMapFormModal}
        size="small"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Header size="medium">Market Map Request</Header>
        <Modal.Content>
          {selectedMarketMapRequest && (
            <MarketMapForm
              formValues={selectedMarketMapRequest}
              onSubmitHandler={handleSubmitMarketMapRequest}
              renderRemoteSubmit={() => (
                <RemoteSubmit submit={isSubmit} setSubmit={setIsSubmit} />
              )}
              isSingleSDG
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleCloseModal()}>Close</Button>
          {selectedMarketMapRequest &&
            selectedMarketMapRequest.status === 'pending' && (
              <>
                <Button positive onClick={() => handleRemoteSubmitForm()}>
                  Save
                </Button>
                <Button.Group style={{ float: 'left' }}>
                  <Button
                    positive
                    icon
                    labelPosition="left"
                    onClick={() =>
                      handleApproveRejectMarketMapRequest(
                        selectedMarketMapRequest.id,
                        'approve'
                      )
                    }
                  >
                    <Icon name="check" />
                    Approve
                  </Button>
                  <Button.Or />
                  <Button
                    negative
                    icon
                    labelPosition="right"
                    onClick={() =>
                      handleApproveRejectMarketMapRequest(
                        selectedMarketMapRequest.id,
                        'reject'
                      )
                    }
                  >
                    <Icon name="close" />
                    Reject
                  </Button>
                </Button.Group>
              </>
            )}
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default withRouter(MarketMapRequestList);
